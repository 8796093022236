body {
  min-height: 100vh;
  margin: 0 auto;
  padding: 0;
  background: transparent linear-gradient(241deg, #5A8BB9 0%, #ECF6FF 100%) 0% 0% no-repeat padding-box;
  opacity: 1;

}

* {
  font-family: "Montserrat";
  font-weight: 400;
}

a,
a:hover {
  text-decoration: none;
  color: #fff;
}

.no-padding {
  padding: 0px !important;
}

.no-margin {
  margin: 0px !important;
}

input:focus {
  outline: 0px !important;
  border-color: #000 !important;
  box-shadow: none !important;
}

input::placeholder {
  color: #95989A;
}

.ecm_form_mx_width {
  max-width: 868px;
  margin: 0 auto;
}

button[type="button"]:focus,
.dash_modal_btn2:focus {
  /* outline: 5px auto -webkit-focus-ring-color !important; */
  outline: none;
  box-shadow: 0px 0px 4px 4px #a6c8ff !important;
}

.ecm_main_wrapper {
  width: 100%;
  height: 100vh;
}

.ecm_signup_form_main {
  width: 100%;
  height: auto;
  background: #fff;
  border-radius: 40px;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, .2);
}

.ecm_signup_header {
  width: 100%;
  height: auto;
  padding: 60px 50px 60px 91px;
  background: transparent linear-gradient(89deg, #30679A 0%, #EAF5FE 100%) 0% 0% no-repeat padding-box;
  border-radius: 40px 40px 0px 0px;
  position: relative;
}

.ecm_signup_header h4 {
  font-family: "MontserratBold";
  font-size: 25px;
  text-align: left;
  letter-spacing: 0;
  color: #FFFFFF;
  margin: 0;
}

.ecm_signup_body {
  background: #fff;
  border-radius: 0px 0px 40px 40px;
}

.ecm_project_modals .ecm_theme_btn {
  width: auto;
  height: 37px;
  background: #5A8BB9 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 5px #5A8BB94D !important;
  border-radius: 50px;
  color: #fff;
  font-family: "MontserratBold";
  float: right;
  font-size: 16px;
  border: 0px;
  transition: all .4s;
  cursor: pointer;
  padding: 0px 10px;
}

.ecm_project_modals .ecm_theme_btn a {
  color: #fff;
  font-family: "MontserratBold";
}

.ecm_project_modals .ecm_theme_btn:focus {
  outline: none !important;
  border: 1px solid #30679A;
}

.ecm_project_modals .ecm_theme_btn:hover {
  background: #30679a !important;
}

.ecm_project_modals .ecm_modal_btn {
  float: left;
  border: 1px solid #30679A;
  background: #fff;
  box-shadow: none !important;
  margin-top: 0px;
}

.ecm_project_modals .ecm_modal_btn {
  color: #5A8BB9;
  font-family: "Montserrat";
}

.ecm_project_modals .ecm_modal_btn:hover {
  color: #fff;
  ;
  box-shadow: 0px 5px 5px #5A8BB94D !important;
}

.ecm_project_modals {
  width: 100%;
  height: auto;
  border-bottom: 1px solid #95989A;
  padding: 6px 0px 0px;
}

.ecm_project_modals ul {
  padding: 0px 50px;
  width: 100%;
  height: auto;
  margin: 0;
  text-align: center;
}

.ecm_project_modals ul li {
  list-style: none;
  display: inline-block;
  margin-right: 20px;
}

.ecm_modal_btn i {
  background: #5a8bb9;
  color: #fff;
  padding: 5px 7px;
  border-radius: 50px;
  font-size: 14px;
  margin-right: 10px;
}

.ecm_modal_btn:hover i {
  background: #fff;
  color: #5a8bb9;
}

.ecm_theme_btn.ecm_sub {
  width: 100%;
  padding: 0px 10px;
}

.ecm_project_scroll {
  width: 100%;
  max-height: 308px;
  overflow-y: scroll;
  padding: 8px 75px;
  border-bottom: 1px solid #95989A;
}

.ecm_project_scroll::-webkit-scrollbar-track {
  background-color: #EAF5FE;
}

.ecm_project_scroll::-webkit-scrollbar {
  width: 12px;
  background-color: #5A8BB9;
}

/* Buttons */
.ecm_project_scroll::-webkit-scrollbar-button:single-button {
  background-color: #5A8BB9;
  display: block;
  border-style: solid;
  height: 15px;
  width: 16px;
}

/* Up */
.ecm_project_scroll::-webkit-scrollbar-button:single-button:vertical:decrement {
  border-width: 0 6px 6px 6px;
  border-color: transparent transparent #fff transparent;
}

.ecm_project_scroll::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  border-color: transparent transparent #fff transparent;
}

/* Down */
.ecm_project_scroll::-webkit-scrollbar-button:single-button:vertical:increment {
  border-width: 6px 6px 0 6px;
  border-color: #fff transparent transparent transparent;
}

.ecm_project_scroll::-webkit-scrollbar-button:vertical:single-button:increment:hover {
  border-color: #000 transparent transparent transparent;
}

.ecm_project_scroll::-webkit-scrollbar-thumb {
  background-color: #5A8BB9;
  border: 1px solid #30679A;
}

.ecm_project {
  text-align: left !important;
  padding: 0 !important;
  list-style: none;
}

.ecm_project li {
  width: 100%;
  position: relative;
  border: 1px solid #D9D9D9;
  box-shadow: 0px 5px 5px #D9D9D94D;
  border-radius: 20px;
  margin-bottom: 7px;
}

.ecm_projects_list {
  width: 100%;
  height: auto;
  background: #fff;
  border-left: 14px solid #8EE952;
  border-radius: 20px;
  padding: 30px 0px;
  padding-left: 15px;
}

.ecm_projects_list.ecm_red {
  border-left: 14px solid #E95252 !important;
}

.ecm_projects_list i {
  background: #5A8BB9;
  color: #fff;
  padding: 5px 6px;
  border-radius: 50px;
  position: absolute;
  top: 8px;
  right: 15px;
  cursor: pointer;
}

.ecm_projects_list h4 {
  font-size: 14px;
  font-family: "MontserratBold";
  margin: 0;
}

.ecm_projects_list p {
  font-size: 12px;
  margin: 0;
}

.ecm_signup_form label,
.ecm_signup_form label u {
  margin: 0px;
  font-family: "MontserratBold";
  color: #000;
  font-size: 14px;
}

.ecm_signup_form input {
  font-size: 16px;
  border: 0px;
  border-bottom: 1px solid #95989A !important;
  border-radius: 0px;
  padding: 5px 0px;
  color: #000;
  height: 24px;
}

.ecm_signup_form .ecm_remember {
  text-align: left;
  font-family: "Montserrat";
  font-size: 16px;
  letter-spacing: 0;
  color: #000;
  padding-top: 3px;
}

.ecm_signup_form {
  padding: 8px 75px;
}

/* custom select */
.ecm_select-box {
  position: relative;
  display: block;
  width: 100%;
  font-size: 16px;
  border: 0px;
  border-radius: 0px;
  padding: 5px 0px;
  color: #000;
  height: 24px;
}

.ecm_select-box__current {
  position: relative;
  cursor: pointer;
  outline: none;
  border-bottom: 1px solid #95989A;
}

.ecm_select-box__current:focus {
  border-bottom: 2px solid #30679A;
}

.ecm_select-box__current:focus+.ecm_select-box__list {
  opacity: 1;
  -webkit-animation-name: none;
  animation-name: none;
}

.ecm_select-box__current:focus+.ecm_select-box__list .ecm_select-box__option {
  cursor: pointer;
}

.ecm_select-box__current:focus .ecm_select-box__icon {
  -webkit-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}

.ecm_select-box__icon {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 14px;
  transition: 0.2s ease;
}

.ecm_select-box__value {
  display: flex;
}

.ecm_select-box__input {
  display: none;
}

.ecm_select-box__input:checked+.ecm_select-box__input-text {
  display: block;
}

.ecm_select-box__input-text {
  display: none;
  width: 100%;
  margin: 0;
  background-color: #fff;
}

.ecm_select-box__list {
  position: absolute;
  top: 35px;
  width: 100%;
  padding: 0;
  list-style: none;
  opacity: 0;
  -webkit-animation-name: HideList;
  animation-name: HideList;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: step-start;
  animation-timing-function: step-start;
  box-shadow: 0px 3px 6px #00000029;
}

.ecm_select-box__list li label {
  font-family: "Montserrat";
  font-size: 18px;
  border-bottom: 1px solid #eee;
}

.ecm_select-box__option {
  display: block;
  padding: 20px;
  background-color: #fff;
}

.ecm_select-box__option:hover,
.ecm_select-box__option:focus {
  color: #546c84;
  background-color: #fbfbfb;
}

@-webkit-keyframes HideList {
  from {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }

  to {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
}

@keyframes HideList {
  from {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }

  to {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
}

.ecm_select {
  position: relative;
}

.ecm_custom_select {
  font-size: 16px;
  border-radius: 0;
  border: none;
  background: transparent;
  width: 100%;
  overflow: hidden;
  padding: 0;
  height: auto;
  color: #000;
  border-bottom: 1px solid #95989A;
  appearance: none;
  -webkit-appearance: none
}

.ecm_custom_select:focus {
  outline: none;
  box-shadow: none;
  border-bottom: 2px solid #30679A;
}

.ecm_custom_select optgroup {
  font-size: 16px;
  background: #fff;
  padding: 115px 0px;
}

.ecm_custom_caret:after {
  position: absolute;
  bottom: 5px;
  right: 10px;
  width: 0;
  height: 0;
  padding: 0;
  content: '';
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #30679A;
  pointer-events: none !important;
}

.ecm_custom_select:focus .ecm_custom_caret:after {
  transform: rotate(180deg) !important;
  display: none !important;
}

/* modal css */
.ecm_modal .modal-content {
  border: 0px !important;
  background: none !important;
}

.ecm_modal_closed {
  background: #5A8BB9;
  color: #e4f1fb;
  padding: 5px 8px 6px 7px;
  border-radius: 50px;
  position: absolute;
  top: 15px;
  right: 27px;
  cursor: pointer;
  z-index: 999;
}

/* .ecm_modal .ecm_signup_form {
  padding: 60px 150px 60px;
} */

/* bottom btn */
.w-13 {
  width: 26px;
  margin-right: 5px;
}

.ecm_theme_btn {
  width: 123px;
  height: 42px;
  background: #5A8BB9 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 5px #5A8BB94D !important;
  border-radius: 50px;
  color: #fff;
  font-family: "MontserratBold";
  float: right;
  font-size: 18px;
  border: 0px;
  transition: all .4s;
  cursor: pointer;
  margin-bottom: 20px;
  margin-top: 50px;
}

.ecm_theme_btn a {
  color: #fff;
  font-family: "MontserratBold";
}

.ecm_theme_btn.add_formula {
  padding: 0px 7px !important;
}

.ecm_google_btn:hover,
.ecm_fb_btn:hover,
.ecm_google_btn:focus,
.ecm_fb_btn:focus {
  box-shadow: 0px 7px 10px #929eaa;
}

.ecm_theme_btn:focus {
  border: 1px solid #30679A;
  outline: none !important;
}

.ecm_theme_btn:hover {
  background: #30679a !important;
}

.ecm_back {
  float: left;
  border: 1px solid #30679A;
  background: #fff;
  box-shadow: none !important;
}

.ecm_back {
  color: #5A8BB9;
  font-family: "Montserrat";
}

.ecm_back:hover {
  color: #fff;
  font-family: "MontserratBold";
}

.ecm_back:hover {
  box-shadow: 0px 5px 5px #5A8BB94D !important;
}

.ecm_paste_input:focus {
  border: 1px solid #D9D9D9 !important;
}

.ecm_paste_btn .input-group-text {
  background: #F8F8F8 !important;
  height: 80px !important;
  width: 80px !important;
  text-align: center !important;
  justify-content: center !important;
  cursor: pointer;
}

.ecm_paste_input::placeholder {
  padding: 0px;
  color: #D9D9D9;
}

/* end */
/* custom radio/checkbox */
/* Customize the label (the container) */
.ecm_container {
  width: 100%;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.ecm_container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.ecm_checkmark {
  position: absolute;
  top: 5px;
  left: 0;
  height: 1.2rem;
  width: 1.2rem;
  border-radius: 50px;
  border: 1px solid #95989A;
  background: #fff;
}

input[type="checkbox"]:focus+span,
input[type="radio"]:focus+span {
  box-shadow: 0px 0px 3px 3px #a6c8ff;
  outline: none;
}

/* On mouse-over, add a grey background color */
.ecm_container:hover input~.ecm_checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.ecm_container input:checked~.ecm_checkmark {
  background-color: #346a9c;
  border-radius: 50px;
}

/* Create the ecm_checkmark/indicator (hidden when not checked) */
.ecm_checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the ecm_checkmark when checked */
.ecm_container input:checked~.ecm_checkmark:after {
  display: block;
}

/* Style the ecm_checkmark/indicator */
.ecm_container .ecm_checkmark:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* end */
/*********** tag input css ************/
.ecm_paste_taginput {
  height: 172px;
  max-height: 172px !important;
  border: 1px solid #D9D9D9 !important;
  padding: 10px !important;
  font-size: 14px !important;
  overflow-y: scroll;
}

.ecm_paste_taginput::-webkit-scrollbar-track {
  background-color: #EAF5FE;
}

.ecm_paste_taginput::-webkit-scrollbar {
  width: 12px;
  background-color: #5A8BB9;
}

/* Buttons */
.ecm_paste_taginput::-webkit-scrollbar-button:single-button {
  background-color: #5A8BB9;
  display: block;
  border-style: solid;
  height: 15px;
  width: 16px;
}

/* Up */
.ecm_paste_taginput::-webkit-scrollbar-button:single-button:vertical:decrement {
  border-width: 0 6px 6px 6px;
  border-color: transparent transparent #fff transparent;
}

.ecm_paste_taginput::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  border-color: transparent transparent #fff transparent;
}

/* Down */
.ecm_paste_taginput::-webkit-scrollbar-button:single-button:vertical:increment {
  border-width: 6px 6px 0 6px;
  border-color: #fff transparent transparent transparent;
}

.ecm_paste_taginput::-webkit-scrollbar-button:vertical:single-button:increment:hover {
  border-color: #000 transparent transparent transparent;
}

.ecm_paste_taginput::-webkit-scrollbar-thumb {
  background-color: #5A8BB9;
  border: 1px solid #30679A;
}

/*  */
.react-tagsinput {
  background-color: transparent;
  border: 0px !important;
}

.react-tagsinput-tag {
  background: #F8F8F8 !important;
  border: 1px solid #D9D9D9 !important;
  border-radius: 50px !important;
  color: #000000 !important;
  padding: 4px 10px !important;
  font-size: 16px;
  margin-right: 0px !important;
}

.react-tagsinput-remove {
  background: #30679A;
  color: #fff !important;
  border-radius: 50px;
  padding: 0px 4px 0px 4px !important;
  margin-left: 5px;
}

.react-tagsinput-tag a::before {
  content: "×" !important;
}

/* custon css of multi selector */
.ecm_select .dropdown-heading {
  padding: 5px 0px 5px 10px;
  border: 0px !important;
  border-bottom: 1px solid #95989A !important;
  border-radius: 0px !important;
}

.ecm_select .select-panel div input {
  display: none !important;
}

.ecm_select .dropdown-content {
  height: auto;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-top: 3px solid #30679A !important;
  border: 0px;
}

.ecm_select .item-renderer input {
  position: absolute;
  opacity: 0;
}

.ecm_select .item-renderer span {
  padding-left: 10px !important;
}

.ecm_select .item-renderer input+span {
  position: relative;
  cursor: pointer;
  padding: 0;
  color: #000;
  font-size: 16px;
}

.ecm_select .item-renderer input+span:before {
  position: absolute;
  top: 3;
  left: 0;
  height: 1.2rem;
  width: 1.2rem;
  border-radius: 50px;
  border: 1px solid #95989A;
  background: #fff;
}

/* .item-renderer input:hover + span:before {
background: #f35429;
} */
.ecm_select .item-renderer input:focus+span:before {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}

.ecm_select .item-renderer input:checked+span:before {
  background-color: #346a9c;
  border-radius: 50px;
}

.ecm_select .item-renderer input:disabled+span {
  color: #b8b8b8;
  cursor: auto;
}

.ecm_select .item-renderer input:disabled+span:before {
  box-shadow: none;
  background: #ddd;
}

.ecm_select .item-renderer input:checked+span:after {
  position: absolute;
  left: 7px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.ecm_select .dropdown-content {
  max-height: 450px !important;
}

.ecm_select .dropdown-content::-webkit-scrollbar-track {
  background-color: #EAF5FE;
}

.ecm_select .dropdown-content::-webkit-scrollbar {
  width: 12px;
  background-color: #5A8BB9;
}

/* Buttons */
.ecm_select .dropdown-content::-webkit-scrollbar-button:single-button {
  background-color: #5A8BB9;
  display: block;
  border-style: solid;
  height: 15px;
  width: 16px;
}

/* Up */
.ecm_select .dropdown-content::-webkit-scrollbar-button:single-button:vertical:decrement {
  border-width: 0 6px 6px 6px;
  border-color: transparent transparent #fff transparent;
}

.ecm_select .dropdown-content::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  border-color: transparent transparent #fff transparent;
}

/* Down */
.ecm_select .dropdown-content::-webkit-scrollbar-button:single-button:vertical:increment {
  border-width: 6px 6px 0 6px;
  border-color: #fff transparent transparent transparent;
}

.ecm_select .dropdown-content::-webkit-scrollbar-button:vertical:single-button:increment:hover {
  border-color: #000 transparent transparent transparent;
}

.ecm_select .dropdown-content::-webkit-scrollbar-thumb {
  background-color: #5A8BB9;
  border: 1px solid #30679A;
}

.ecm_select .multi-select .dropdown[aria-expanded="false"] .dropdown-heading-dropdown-arrow span {
  transform: rotate(180deg) !important;
}

.ecm_select .dropdown-heading-dropdown-arrow span {
  border-color: transparent transparent rgb(48, 103, 154) !important;
  border-width: 0px 6px 6px !important;
}

.ecm_select .select-panel .select-list {
  column-count: 5;
  text-align: center;
}

.ecm_select .select-panel>.select-item,
.ecm_select .select-panel>div {
  display: none !important;
}

.ecm_select .select-panel .select-item:hover {
  background-color: rgb(241, 241, 241) !important;
}

.ecm_select .select-panel>.select-list .select-item[aria-selected="true"] {
  background-color: rgb(241, 241, 241) !important;
}

.ecm_select .dropdown-heading-value,
.dropdown-heading {
  padding-left: 0px !important;
}

/* custon css of multi selector end */
/* end */
/* width drop list */
.ecm_width-selector-inner__control {
  font-size: 16px;
  background-color: transparent !important;
  box-shadow: none !important;
  border-radius: 0;
  border: none !important;
  background: transparent;
  width: 100%;
  overflow: hidden;
  padding: 0;
  height: auto;
  color: #000;
  border-bottom: 1px solid #95989A !important;
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
}

.ecm_width-selector-inner__control.css-e56m7-control:after,
.ecm_width-selector-inner__control--is-focused:after {
  position: absolute !important;
  font-size: 18px !important;
  content: "\f0d7" !important;
  right: 11px;
  color: #30679a;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  z-index: 9999;
}

.ecm_width-selector-inner__control--is-focused.ecm_width-selector-inner__control--menu-is-open:after {
  transform: rotate(180deg);
}

.ecm_width-selector-inner__control:focus {
  box-shadow: none !important;
  outline: none !important;
}

.ecm_width-selector-inner__value-container {
  padding-left: 0px !important;
}

.css-1okebmr-indicatorSeparator,
.ecm_width-selector-inner__indicators svg {
  display: none !important;
}

.ecm_width-selector-inner__menu {
  margin-top: 0px !important;
  border-top: 2px solid #30679a;
}

.ecm_width-selector-inner__menu,
.ecm_width-selector-inner__menu-list {
  min-height: 420px !important;
}

.ecm_width-selector-inner__menu-list .ecm_width-selector-inner__option {
  background: transparent !important;
  background-color: transparent !important;
  color: #000 !important;
  width: 20%;
  float: left;
  text-align: center;
}

.ecm_width-selector-inner__menu-list .ecm_width-selector-inner__option:hover,
.ecm_width-selector-inner__menu-list .ecm_width-selector-inner__option__is-selected {
  background-color: #f2f2f2 !important;
  background: #f2f2f2 !important;
}

/* width drop list end */













/* wide screen end */
@media (min-width:992px) and (max-width:1900px) {
  .ecm_theme_btn.add_formula {
    padding: 0px 4px !important;
  }

  .ecm_form_mx_width {
    max-width: 540px;
  }

  .ecm_modal .ecm_signup_form {
    padding: 37px 90px !important;
  }

  .ecm_width-selector-inner__control {
    font-size: 9px !important;
    min-height: 20px !important;
  }

  .ecm_width-selector-inner__value-container {
    height: 16px;
    padding: 0 !important;
    line-height: 10px;
  }

  .ecm_width-selector-inner__single-value {
    top: 65% !important;
  }

  .css-w8afj7-Input {
    margin: 0px !important;
    padding-bottom: 0px !important;
  }

  .ecm_width-selector-inner__indicator {
    padding: 0px !important;
  }

  .ecm_width-selector-inner__control,
  .ecm_width-selector-inner__menu-list .ecm_width-selector-inner__option {
    font-size: 14px;
  }

  .ecm_project_scroll {
    max-height: 300px;
    padding: 6px 70px;
  }

  .ecm_width-selector-inner__menu-list .ecm_width-selector-inner__option {
    font-size: 11px !important;
  }

  .ecm_width-selector-inner__menu,
  .ecm_width-selector-inner__menu-list {
    min-height: 330px !important;
  }

  .ecm_project_modals .ecm_theme_btn {
    width: auto;
    height: 2px;
    font-size: 9px;
    padding-left: 5px;
  }

  .w-13 {
    width: 16px;
    margin-right: 5px;
  }

  .ecm_modal_btn i {
    background: #5a8bb9;
    color: #fff;
    padding: 4px 5px;
    border-radius: 50px;
    font-size: 7px;
    margin-right: 8px;
  }

  .ecm_theme_btn.ecm_sub {
    width: 100%;
  }

  .ecm_project_modals ul {
    padding: 0px 70px;
  }

  .ecm_project_modals ul li {
    margin-right: 10px;
  }

  .ecm_signup_form .ecm_remember {
    margin-bottom: 5px !important;
  }

  .ecm_container {
    padding-left: 25px;
  }

  .ecm_select .dropdown-heading-dropdown-arrow span {
    border-width: 0px 5px 5px !important;
  }

  .ecm_container.ecm_remember .ecm_checkmark {
    top: 2px !important;
    height: 1rem;
    width: 1rem;
  }

  .ecm_container.ecm_remember .ecm_checkmark:after {
    left: 5px;
    top: 3px;
    width: 4px;
    height: 7px;
    border-width: 0 2px 2px 0;
  }

  .ecm_select .dropdown-heading-value,
  .dropdown-heading {
    font-size: 9px;
    line-height: 18px !important;
    height: 20px !important;
  }

  .ecm_select .dropdown-heading {
    line-height: 10px !important;
  }

  .ecm_custom_select,
  .ecm_select .item-renderer input+span {
    font-size: 9px !important;
  }

  .ecm_paste_taginput {
    font-size: 12px !important;
  }

  .ecm_select .item-renderer input+span:before {
    top: 0px !important;
    height: 1rem;
    width: 1rem;
  }

  .ecm_select .item-renderer input:checked+span:after {
    left: 6px;
    top: 4px;
    width: 4px;
    height: 7px;
    border-width: 0 2px 2px 0;
  }

  .ecm_form_upload_message p {
    height: 50px !important;
    font-size: 9px !important;
    height: 24px !important;
  }

  .ecm_paste_btn .input-group-text {
    height: 42px !important;
    width: 42px !important;
    padding: 4px !important;
  }

  .ecm_theme_btn {
    margin: 30px 0 0px;
  }

  .ecm_paste_taginput {
    height: 100px;
  }

  .react-tagsinput-tag {
    font-size: 9px;
  }

  .ecm_signup_header h4 {
    font-size: 14px;
  }

  img[alt="Logo"] {
    width: 60px !important;
  }

  .ecm_signup_form label,
  .ecm_signup_form label,
  .ecm_signup_form label u {
    font-size: 9px !important;
  }

  .ecm_signup_form input {
    font-size: 9px;
    padding: 2px 0px 5px;
    height: 15px;
  }

  .ecm_signup_form .remember {
    font-size: 12px;
  }

  .ecm_google_btn,
  .ecm_fb_btn {
    font-size: 14px;
    padding: 6px 17px;
  }

  .ecm_theme_btn {
    width: 70px;
    height: 26px;
    font-size: 11px;
  }

  .al_paste_btn .input-group-text {
    height: 60px !important;
    width: 60px !important;
  }

  .al_signup_header {
    padding: 32px 37px 32px 55px;
    border-radius: 20px 20px 0px 0px;
  }

  .ecm_signup_header {
    padding: 32px 37px 32px 55px;
    border-radius: 20px 20px 0px 0px;
  }

  .ecm_signup_body {
    border-radius: 0px 0px 20px 20px;
  }

  .ecm_google_btn img,
  .ecm_fb_btn img {
    width: 20px !important;
  }

  .ecm_hidden_pass {
    width: 15px;
  }

  .ecm_signup_form .form-group {
    margin-bottom: 10px;
  }

  .custom-control {
    margin-top: 15px;
  }

  .ecm_signup_form_main {
    margin: 30px 0px;
  }
}

/* wide screen end */
/* desktop screen */
@media (min-width:768px) and (max-width:991px) {
  .ecm_theme_btn.add_formula {
    padding: 0px 4px !important;
  }

  .ecm_form_mx_width {
    max-width: 540px;
  }

  .ecm_modal .ecm_signup_form {
    padding: 37px 90px !important;
  }

  .ecm_width-selector-inner__control {
    font-size: 9px !important;
    min-height: 20px !important;
  }

  .ecm_width-selector-inner__value-container {
    height: 16px;
    padding: 0 !important;
    line-height: 10px;
  }

  .ecm_width-selector-inner__single-value {
    top: 65% !important;
  }

  .css-w8afj7-Input {
    margin: 0px !important;
    padding-bottom: 0px !important;
  }

  .ecm_width-selector-inner__indicator {
    padding: 0px !important;
  }

  .ecm_width-selector-inner__control,
  .ecm_width-selector-inner__menu-list .ecm_width-selector-inner__option {
    font-size: 14px;
  }

  .ecm_project_scroll {
    max-height: 300px;
    padding: 6px 70px;
  }

  .ecm_width-selector-inner__menu-list .ecm_width-selector-inner__option {
    font-size: 11px !important;
  }

  .ecm_width-selector-inner__menu,
  .ecm_width-selector-inner__menu-list {
    min-height: 330px !important;
  }

  .ecm_modal_btn i {
    background: #5a8bb9;
    color: #fff;
    padding: 4px 5px;
    border-radius: 50px;
    font-size: 7px;
    margin-right: 8px;
  }

  .ecm_project_modals .ecm_theme_btn {
    width: auto;
    height: 25px;
    font-size: 9px;
    padding-left: 5px;
  }

  .w-13 {
    width: 16px !important;
    margin-right: 5px !important;
  }

  .ecm_signup_form .ecm_remember {
    margin-bottom: 5px !important;
  }

  .ecm_container {
    padding-left: 25px;
  }

  .ecm_select .dropdown-heading-dropdown-arrow span {
    border-width: 0px 5px 5px !important;
  }

  .ecm_container.ecm_remember .ecm_checkmark {
    top: 2px !important;
    height: 1rem;
    width: 1rem;
  }

  .ecm_container.ecm_remember .ecm_checkmark:after {
    left: 5px;
    top: 3px;
    width: 4px;
    height: 7px;
    border-width: 0 2px 2px 0;
  }

  .ecm_select .dropdown-heading-value,
  .dropdown-heading {
    font-size: 9px;
    line-height: 18px !important;
    height: 20px !important;
  }

  .ecm_select .dropdown-heading {
    line-height: 10px !important;
  }

  .ecm_custom_select,
  .ecm_select .item-renderer input+span {
    font-size: 9px !important;
  }

  .ecm_paste_taginput {
    font-size: 12px !important;
  }

  .ecm_select .item-renderer input+span:before {
    top: 0px !important;
    height: 1rem;
    width: 1rem;
  }

  .ecm_select .item-renderer input:checked+span:after {
    left: 6px;
    top: 4px;
    width: 4px;
    height: 7px;
    border-width: 0 2px 2px 0;
  }

  .ecm_form_upload_message p {
    height: 50px !important;
    font-size: 9px !important;
    height: 24px !important;
  }

  .ecm_paste_btn .input-group-text {
    height: 42px !important;
    width: 42px !important;
    padding: 4px !important;
  }

  .ecm_theme_btn {
    margin: 30px 0 0px;
  }

  .ecm_paste_taginput {
    height: 100px;
  }

  .react-tagsinput-tag {
    font-size: 9px;
  }

  .ecm_signup_header h4 {
    font-size: 14px;
  }

  img[alt="Logo"] {
    width: 60px !important;
  }

  .ecm_signup_form label,
  .ecm_signup_form label,
  .ecm_signup_form label u {
    font-size: 9px !important;
  }

  .ecm_signup_form input {
    font-size: 9px;
    padding: 2px 0px 5px;
    height: 15px;
  }

  .ecm_signup_form .remember {
    font-size: 12px;
  }

  .ecm_google_btn,
  .ecm_fb_btn {
    font-size: 14px;
    padding: 6px 17px;
  }

  .ecm_theme_btn {
    width: 70px;
    height: 26px;
    font-size: 11px;
  }

  .al_paste_btn .input-group-text {
    height: 60px !important;
    width: 60px !important;
  }

  .al_signup_header {
    padding: 32px 37px 32px 55px;
    border-radius: 20px 20px 0px 0px;
  }

  .ecm_signup_header {
    padding: 32px 37px 32px 55px;
    border-radius: 20px 20px 0px 0px;
  }

  .ecm_signup_body {
    border-radius: 0px 0px 20px 20px;
  }

  .ecm_google_btn img,
  .ecm_fb_btn img {
    width: 18px !important;
  }

  .ecm_hidden_pass {
    width: 15px;
  }

  .ecm_signup_form .form-group {
    margin-bottom: 10px;
  }

  .custom-control {
    margin-top: 15px;
  }

  .ecm_signup_form_main {
    margin: 30px 0px;
  }
}

/* desktop screen end */
/* tab screen */
@media (min-width:567px) and (max-width:768px) {
  .ecm_theme_btn.add_formula {
    padding: 0px 4px !important;
  }

  .ecm_form_mx_width {
    max-width: 540px;
  }

  .ecm_modal .ecm_signup_form {
    padding: 37px 90px !important;
  }

  .ecm_width-selector-inner__control {
    font-size: 9px !important;
    min-height: 20px !important;
  }

  .ecm_width-selector-inner__value-container {
    height: 16px;
    padding: 0 !important;
    line-height: 10px;
  }

  .ecm_width-selector-inner__single-value {
    top: 65% !important;
  }

  .css-w8afj7-Input {
    margin: 0px !important;
    padding-bottom: 0px !important;
  }

  .ecm_width-selector-inner__indicator {
    padding: 0px !important;
  }

  .ecm_width-selector-inner__control,
  .ecm_width-selector-inner__menu-list .ecm_width-selector-inner__option {
    font-size: 14px;
  }

  .ecm_project_scroll {
    max-height: 300px;
    padding: 6px 70px;
  }

  .ecm_width-selector-inner__menu-list .ecm_width-selector-inner__option {
    font-size: 11px !important;
  }

  .ecm_width-selector-inner__menu,
  .ecm_width-selector-inner__menu-list {
    min-height: 330px !important;
  }

  .ecm_modal_btn i {
    background: #5a8bb9;
    color: #fff;
    padding: 4px 5px;
    border-radius: 50px;
    font-size: 7px;
    margin-right: 8px;
  }

  .ecm_project_modals .ecm_theme_btn {
    width: auto;
    height: 25px;
    font-size: 9px;
    padding-left: 5px;
  }

  .w-13 {
    width: 16px;
    margin-right: 5px;
  }

  .ecm_signup_form .ecm_remember {
    margin-bottom: 5px !important;
  }

  .ecm_container {
    padding-left: 25px;
  }

  .ecm_select .dropdown-heading-dropdown-arrow span {
    border-width: 0px 5px 5px !important;
  }

  .ecm_container.ecm_remember .ecm_checkmark {
    top: 2px !important;
    height: 1rem;
    width: 1rem;
  }

  .ecm_container.ecm_remember .ecm_checkmark:after {
    left: 5px;
    top: 3px;
    width: 4px;
    height: 7px;
    border-width: 0 2px 2px 0;
  }

  .ecm_select .dropdown-heading-value,
  .dropdown-heading {
    font-size: 9px;
    line-height: 18px !important;
    height: 20px !important;
  }

  .ecm_select .dropdown-heading {
    line-height: 10px !important;
  }

  .ecm_custom_select,
  .ecm_select .item-renderer input+span {
    font-size: 9px !important;
  }

  .ecm_paste_taginput {
    font-size: 12px !important;
  }

  .ecm_select .item-renderer input+span:before {
    top: 0px !important;
    height: 1rem;
    width: 1rem;
  }

  .ecm_select .item-renderer input:checked+span:after {
    left: 6px;
    top: 4px;
    width: 4px;
    height: 7px;
    border-width: 0 2px 2px 0;
  }

  .ecm_form_upload_message p {
    height: 50px !important;
    font-size: 9px !important;
    height: 24px !important;
  }

  .ecm_paste_btn .input-group-text {
    height: 42px !important;
    width: 42px !important;
    padding: 4px !important;
  }

  .ecm_theme_btn {
    margin: 30px 0 0px;
  }

  .ecm_paste_taginput {
    height: 100px;
  }

  .react-tagsinput-tag {
    font-size: 9px;
  }

  .ecm_signup_header h4 {
    font-size: 14px;
  }

  img[alt="Logo"] {
    width: 60px !important;
  }

  .ecm_signup_form label,
  .ecm_signup_form label,
  .ecm_signup_form label u {
    font-size: 9px !important;
  }

  .ecm_signup_form input {
    font-size: 9px;
    padding: 2px 0px 5px;
    height: 15px;
  }

  .ecm_signup_form .remember {
    font-size: 12px;
  }

  .ecm_google_btn,
  .ecm_fb_btn {
    font-size: 14px;
    padding: 6px 17px;
  }

  .ecm_theme_btn {
    width: 70px;
    height: 26px;
    font-size: 11px;
  }

  .al_paste_btn .input-group-text {
    height: 60px !important;
    width: 60px !important;
  }

  .al_signup_header {
    padding: 32px 37px 32px 55px;
    border-radius: 20px 20px 0px 0px;
  }

  .ecm_signup_header {
    padding: 32px 37px 32px 55px;
    border-radius: 20px 20px 0px 0px;
  }

  .ecm_signup_body {
    border-radius: 0px 0px 20px 20px;
  }

  .ecm_google_btn img,
  .ecm_fb_btn img {
    width: 18px !important;
  }

  .ecm_hidden_pass {
    width: 15px;
  }

  .ecm_signup_form .form-group {
    margin-bottom: 10px;
  }

  .custom-control {
    margin-top: 15px;
  }
}

/* tab screen end */
/* mobile screen */
@media (min-width:0px) and (max-width:567px) {

  .ecm_theme_btn.add_formula {
    padding: 0px 4px !important;
  }

  .ecm_form_mx_width {
    max-width: 540px;
  }

  .ecm_modal .ecm_signup_form {
    padding: 37px 50px !important;
  }

  .ecm_width-selector-inner__control {
    font-size: 9px !important;
    min-height: 24px !important;
  }

  .ecm_width-selector-inner__indicator {
    padding: 0px !important;
  }

  .ecm_width-selector-inner__control,
  .ecm_width-selector-inner__menu-list .ecm_width-selector-inner__option {
    font-size: 14px;
  }

  .ecm_project_scroll {
    max-height: 300px;
    padding: 6px 70px;
  }

  .ecm_width-selector-inner__menu-list .ecm_width-selector-inner__option {
    font-size: 11px !important;
  }

  .ecm_width-selector-inner__menu,
  .ecm_width-selector-inner__menu-list {
    min-height: 330px !important;
  }

  .ecm_modal_btn i {
    background: #5a8bb9;
    color: #fff;
    padding: 4px 5px;
    border-radius: 50px;
    font-size: 7px;
    margin-right: 8px;
  }

  .ecm_project_modals .ecm_theme_btn {
    width: auto;
    height: 25px;
    font-size: 9px;
    padding-left: 5px;
  }

  .w-13 {
    width: 16px;
    margin-right: 5px;
  }

  .ecm_signup_form .ecm_remember {
    margin-bottom: 5px !important;
  }

  .ecm_container {
    padding-left: 25px;
  }

  .ecm_select .dropdown-heading-dropdown-arrow span {
    border-width: 0px 5px 5px !important;
  }

  .ecm_container.ecm_remember .ecm_checkmark {
    top: 2px !important;
    height: 1rem;
    width: 1rem;
  }

  .ecm_container.ecm_remember .ecm_checkmark:after {
    left: 5px;
    top: 3px;
    width: 4px;
    height: 7px;
    border-width: 0 2px 2px 0;
  }

  .ecm_select .dropdown-heading-value,
  .dropdown-heading {
    font-size: 9px;
    line-height: 18px !important;
    height: 20px !important;
  }

  .ecm_select .dropdown-heading {
    line-height: 10px !important;
  }

  .ecm_custom_select,
  .ecm_select .item-renderer input+span {
    font-size: 9px !important;
  }

  .ecm_paste_taginput {
    font-size: 12px !important;
  }

  .ecm_select .item-renderer input+span:before {
    top: 0px !important;
    height: 1rem;
    width: 1rem;
  }

  .ecm_select .item-renderer input:checked+span:after {
    left: 6px;
    top: 4px;
    width: 4px;
    height: 7px;
    border-width: 0 2px 2px 0;
  }

  .ecm_form_upload_message p {
    height: 50px !important;
    font-size: 9px !important;
    height: 24px !important;
  }

  .ecm_paste_btn .input-group-text {
    height: 42px !important;
    width: 42px !important;
    padding: 4px !important;
  }

  .ecm_theme_btn {
    margin: 30px 0 0px;
  }

  .ecm_paste_taginput {
    height: 100px;
  }

  .react-tagsinput-tag {
    font-size: 9px;
  }

  .ecm_signup_header h4 {
    font-size: 14px;
  }

  img[alt="Logo"] {
    width: 60px !important;
  }

  .ecm_signup_form label,
  .ecm_signup_form label,
  .ecm_signup_form label u {
    font-size: 9px !important;
  }

  .ecm_signup_form input {
    font-size: 9px;
    padding: 2px 0px 5px;
    height: 15px;
  }

  .ecm_signup_form .remember {
    font-size: 12px;
  }

  .ecm_google_btn,
  .ecm_fb_btn {
    font-size: 14px;
    padding: 6px 17px;
  }

  .ecm_theme_btn {
    width: 70px;
    height: 26px;
    font-size: 11px;
  }

  .al_paste_btn .input-group-text {
    height: 60px !important;
    width: 60px !important;
  }

  .al_signup_header {
    padding: 32px 37px 32px 55px;
    border-radius: 20px 20px 0px 0px;
  }

  .ecm_signup_header {
    padding: 32px 37px 32px 55px;
    border-radius: 20px 20px 0px 0px;
  }

  .ecm_signup_body {
    border-radius: 0px 0px 20px 20px;
  }

  .ecm_google_btn img,
  .ecm_fb_btn img {
    width: 18px !important;
  }

  .ecm_hidden_pass {
    width: 15px;
  }

  .ecm_signup_form .form-group {
    margin-bottom: 10px;
  }

  .custom-control {
    margin-top: 15px;
  }

  .ecm_order-xs-2 {
    order: 2;
  }

  .ecm_order-xs-2 h4 {
    text-align: center;
  }

  .ecm_order-xs-1 {
    order: 1;
    text-align: center;
  }

  .ecm_order-xs-1 img {
    float: initial !important;
    margin-bottom: 10px;
  }

  .ecm_signup_header {
    padding: 32px 37px 32px 37px;
  }

}

@media (min-width:0px) and (max-width:400px) {
  .ecm_theme_btn.add_formula {
    padding: 0px 4px !important;
  }

  .ecm_order-xs-2 {
    order: 2;
  }

  .ecm_order-xs-2 h4 {
    text-align: center;
  }

  .ecm_order-xs-1 {
    order: 1;
    text-align: center;
  }

  .ecm_order-xs-1 img {
    float: initial !important;
    margin-bottom: 10px;
  }

  .ecm_signup_header {
    padding: 32px 37px 32px 37px;
  }

  .ecm_signup_body {
    padding: 0px !important;
  }

  .ecm_modal .ecm_signup_form {
    padding: 37px 30px !important;
  }
}

@media screen and (max-width:991px) {

  .ecm_signup_form label,
  .ecm_signup_form label,
  .ecm_signup_form label u,
  .ecm_custom_select,
  .ecm_select .item-renderer input+span,
  .ecm_signup_form input {
    font-size: 9px !important;
  }

  .ecm_modal .ecm_signup_form {
    padding: 37px 90px !important;
  }

  .ecm_theme_btn {
    width: 70px;
    height: 26px;
    font-size: 11px;
    margin: 30px 0 0px;
  }

  .ecm_signup_header h4 {
    font-size: 14px;
  }

  .ecm_signup_header {
    padding: 32px 37px 32px 55px;
    border-radius: 20px 20px 0px 0px;
  }
}

/* mobile screen end */

.move-column-style {
  filter: invert(100%);
  width: 15px;
  padding-right: 5px;
}

/* Formula bar styles */
#formulaBar div {
  display: inline-block;
}