
body{
    min-height: 100vh;
    margin: 0 auto;
    padding: 0;
    background: transparent linear-gradient(241deg, #5A8BB9 0%, #ECF6FF 100%) 0% 0% no-repeat padding-box;
    opacity: 1;

}
*{
	font-family: "Montserrat";
	font-weight: 400;
}
a,a:hover{text-decoration: none;color: #fff;}
.no-padding{padding: 0px !important;}
.no-margin{margin: 0px !important;}
input:focus{
    outline: 0px !important;
    border-color: #000 !important;
    box-shadow:none !important;
}
input::placeholder{color: #95989A;}
.s1_form_mx_width{
    max-width: 868px;
    margin: 0 auto;
}




.s1_main_wrapper{
    width: 100%;
    height: 100vh;
}
.s1_signup_form_main{
    width: 100%;
    height: auto;
    background: #fff;
    border-radius: 40px;
    box-shadow: 10px 10px 10px rgba(0,0,0,.2);
}
.s1_signup_header{
    width: 100%;
    height: auto;
    padding: 60px 50px 60px 91px;
    background: transparent linear-gradient(89deg, #30679A 0%, #EAF5FE 100%) 0% 0% no-repeat padding-box;
    border-radius: 40px 40px 0px 0px;
}
.s1_signup_header h4{
    font-family: "MontserratBold";
    font-size: 25px;
    text-align: left;
    letter-spacing: 0;
    color: #FFFFFF;
    margin: 0;
}
.s1_signup_body{
    background: #fff;
    border-radius: 0px 0px 40px 40px;
    padding: 60px 150px 60px;
}
.s1_signup_form label{
    margin: 0px;
    font-family: "MontserratBold";
    color: #000;
    font-size: 14px;
}
.s1_signup_form input{
    font-size: 16px;
    border: 0px;
    border-bottom: 1px solid #95989A;
    border-radius: 0px;
    padding: 5px 0px;
    color: #000;
    height: 24px;
}
.s1_signup_form .remember{
    text-align: left;
    font-family: "Montserrat" ;
    font-size:  14px ;
    letter-spacing: 0;
    color: #cccccc;
    padding-top: 3px;
}
.custom-radio .custom-control-label.remember::before{
    border: 1px solid #95989A;
    background: #fff;
}
.s1_password_eye{
    position: relative;
}
.s1_hidden_pass{
    position: absolute;
    top: -5px;
    right: 0;
    cursor: pointer;
}
.s1_social_login{
    width: 100%;
    height: auto;
}
.s1_social_login label{
    width: 100%;
    margin-top: 25px;
    margin-bottom: 10px;
    font-family: "MontserratBold";
    color: #000;
    font-size: 14px;
}
.s1_google_btn,.s1_fb_btn{
    width: 100%;
    font-size: 20px;
    font-family: "MontserratBold";
    background: #fff;
    margin-bottom: 10px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    padding: 10px 15px;
}

.s1_fb_btn{
    background: #375D8F;
    color: #fff;
}
.s1_theme_btn{
    width: 123px;
    height: 37px;
    background: #5A8BB9 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 5px #5A8BB94D !important;
    border-radius: 50px;
    color: #fff;
    font-family: "MontserratBold";
    float: right;
    font-size: 18px;
    margin-top: 69px;
    border: 0px;
    transition: all .4s;
    cursor: pointer;
}
.s1_theme_btn a{
    color: #fff;
    font-family: "MontserratBold";
}
.s1_google_btn:hover,.s1_fb_btn:hover,.s1_google_btn:focus,.s1_fb_btn:focus {
    box-shadow: 0px 7px 10px #929eaa;
}
.s1_theme_btn:focus{
    border: 1px solid #30679A;
    outline: none !important;
}
.s1_theme_btn:hover{
    background: #30679a !important;
}












 /* wide screen end */
 @media (min-width:992px) and (max-width:1900px){
    .s1_form_mx_width {
        max-width: 540px; 
    }
    .s1_theme_btn {margin: 70px 0 20px;}
    .s1_signup_header h4 {font-size: 14px; }
    img[alt="Logo"] {  width: 60px !important; }
    .s1_signup_form label { font-size: 9px; }
    .s1_signup_form input { 
        font-size: 9px;
        padding: 2px 0px 5px; 
        height: 15px;
    }
    .s1_signup_form .remember {font-size: 9px; padding-top:4px !important;}
    .s1_google_btn, .s1_fb_btn { 
        font-size: 12px;
        padding: 6px 17px;
    }
    .s1_theme_btn {
        width: 70px;
        height: 24px;
        font-size: 11px;
    }
    .s1_main_wrapper { height: 100vh; }
    .s1_signup_header { 
        padding: 32px 37px 32px 55px;
        border-radius: 20px 20px 0px 0px;
    }
    .s1_signup_body { 
        border-radius: 0px 0px 20px 20px;
        padding: 6px 90px;
        margin-top: 22px;
    }
    .s1_google_btn img, .s1_fb_btn img{
        width: 18px !important;
    }
    .s1_hidden_pass{width: 15px;top: -8px;}
    .s1_signup_form .form-group{
        margin-bottom: 0px;
    }
    .custom-control{margin-top: 5px !important;}


    /* old */
    .s1_signup_form_main{margin: 30px 0px;}
    /* .s1_main_wrapper{ height: auto;} */ 
} 
/* wide screen end */
/* desktop screen */
@media (min-width:768px) and (max-width:991px){

    .s1_form_mx_width {
        max-width: 540px; 
    }
    .s1_theme_btn {margin: 70px 0 20px;}
    .s1_signup_header h4 {font-size: 14px; }
    img[alt="Logo"] {  width: 60px !important; }
    .s1_signup_form label { font-size: 9px; }
    .s1_signup_form input { 
        font-size: 9px;
        padding: 2px 0px 5px; 
        height: 15px;
    }
    .s1_signup_form .remember {font-size: 9px; padding-top:4px !important;}
    .s1_google_btn, .s1_fb_btn { 
        font-size: 12px;
        padding: 6px 17px;
    }
    .s1_theme_btn {
        width: 70px;
        height: 24px;
        font-size: 11px;
    }
    .s1_main_wrapper { height: 100vh; }
    .s1_signup_header { 
        padding: 32px 37px 32px 55px;
        border-radius: 20px 20px 0px 0px;
    }
    .s1_signup_body { 
        border-radius: 0px 0px 20px 20px;
        padding: 6px 90px;
        margin-top: 22px;
    }
    .s1_google_btn img, .s1_fb_btn img{
        width: 18px !important;
    }
    .s1_hidden_pass{width: 15px;top: -8px;}
    .s1_signup_form .form-group{
        margin-bottom: 0px;
    }
    .custom-control{margin-top: 15px;}
 
    /* old */
    .s1_signup_form_main{margin: 30px 0px;}
    /* .s1_main_wrapper{ height: auto;} */
}
/* desktop screen end */
/* tab screen */
@media (min-width:567px) and (max-width:768px){

     .s1_form_mx_width {
        max-width: 540px; 
    }
    .s1_theme_btn {margin: 70px 0 20px;}
    .s1_signup_header h4 {font-size: 14px; }
    img[alt="Logo"] {  width: 60px !important; }
    .s1_signup_form label { font-size: 9px; }
    .s1_signup_form input { 
        font-size: 9px;
        padding: 2px 0px 5px; 
        height: 15px;
    }
    .s1_signup_form .remember {font-size: 9px; padding-top:4px !important;}
    .s1_google_btn, .s1_fb_btn { 
        font-size: 12px;
        padding: 6px 17px;
    }
    .s1_theme_btn {
        width: 70px;
        height: 24px;
        font-size: 11px;
    }
    .s1_main_wrapper { height: 100vh; }
    .s1_signup_header { 
        padding: 32px 37px 32px 55px;
        border-radius: 20px 20px 0px 0px;
    }
    .s1_signup_body { 
        border-radius: 0px 0px 20px 20px;
        padding: 6px 90px;
        margin-top: 22px;
    }
    .s1_google_btn img, .s1_fb_btn img{
        width: 18px !important;
    }
    .s1_hidden_pass{width: 15px;top: -8px;}
    .s1_signup_form .form-group{
        margin-bottom: 0px;
    }
    .custom-control{margin-top: 15px;} 
 
    /* old */
    /* .s1_signup_header h4{font-size: 20px;}
    .s1_signup_form_main{margin: 30px 0px;}
    .s1_main_wrapper{ height: auto;} 
    .s1_theme_btn{float: none;}
     .s1_bottom_btn{
        width: 100%;
        height: auto;
        text-align: center;
        float: left;
    } */
}
/* tab screen end */
/* mobile screen */
@media (min-width:0px) and (max-width:567px){


    .s1_form_mx_width {
        max-width: 540px; 
    }
    .s1_theme_btn {margin: 70px 0 20px;}
    .s1_signup_header h4 {font-size: 14px; }
    img[alt="Logo"] {  width: 60px !important; }
    .s1_signup_form label { font-size: 9px; }
    .s1_signup_form input { 
        font-size: 9px;
        padding: 2px 0px 5px; 
        height: 15px;
    }
    .s1_signup_form .remember {font-size: 9px; padding-top:4px !important;}
    .s1_google_btn, .s1_fb_btn { 
        font-size: 12px;
        padding: 6px 17px;
    }
    .s1_theme_btn {
        width: 70px;
        height: 24px;
        font-size: 11px;
    }
    .s1_main_wrapper { height: 100vh; }
    .s1_signup_header { 
        padding: 32px 37px 32px 55px;
        border-radius: 20px 20px 0px 0px;
    }
    .s1_signup_body { 
        border-radius: 0px 0px 20px 20px;
        padding: 6px 90px;
        margin-top: 22px;
    }
    .s1_google_btn img, .s1_fb_btn img{
        width: 18px !important;
    }
    .s1_hidden_pass{width: 15px;top: -8px;}
    .s1_signup_form .form-group{
        margin-bottom: 0px;
    }
    .custom-control{margin-top: 15px;}
    .s1_order-xs-2{order: 2;} 
    .s1_order-xs-2 h4{text-align: center;}
   .s1_order-xs-1{order: 1;text-align: center;}
   .s1_order-xs-1 img{
       float: initial !important;
       margin-bottom: 10px;
   }
   .s1_signup_header {padding: 32px 37px 32px 37px;}
 
    /* old */
    /* .s1_signup_body,.s1_signup_header{
        padding: 30px;
    }
    .s1_signup_header h4{font-size: 16px;}
    .s1_theme_btn{margin-top: 30px;}
    .s1_order-xs-2{order: 2;} 
     .s1_order-xs-2 h4{text-align: center;}
    .s1_order-xs-1{order: 1;text-align: center;}
    .s1_order-xs-1 img{
        float: initial !important;
        margin-bottom: 10px;
    }
    .s1_signup_form_main{margin: 30px 0px;}
    .s1_main_wrapper{ height: auto;}
    .s1_theme_btn{float: none;}
    .s1_bottom_btn{
        width: 100%;
        height: auto;
        text-align: center;
        float: left;
    } */
}
@media (min-width:0px) and (max-width:400px){
    .s1_order-xs-2{order: 2;} 
    .s1_order-xs-2 h4{text-align: center;}
   .s1_order-xs-1{order: 1;text-align: center;}
   .s1_order-xs-1 img{
       float: initial !important;
       margin-bottom: 10px;
   }
   .s1_signup_header {padding: 32px 37px 32px 37px;}
   .s1_signup_body {padding: 6px 40px;}
}
/* mobile screen end */