body {
  min-height: 100vh;
  margin: 0 auto;
  padding: 0;
  background: transparent linear-gradient(241deg, #5A8BB9 0%, #ECF6FF 100%) 0% 0% no-repeat padding-box;
  opacity: 1;

}

* {
  font-family: "Montserrat";
  font-weight: 400;
}

a,
a:hover {
  text-decoration: none;
  color: #fff;
}

.no-padding {
  padding: 0px !important;
}

.no-margin {
  margin: 0px !important;
}

input:focus {
  outline: 0px !important;
  border-color: #000 !important;
  box-shadow: none !important;
}

input::placeholder {
  color: #95989A;
}

.agm_form_mx_width {
  max-width: 868px;
  margin: 0 auto;
}

.agm_main_wrapper {
  width: 100%;
}

.agm_signup_form_main {
  width: 100%;
  height: auto;
  background: #fff;
  border-radius: 40px;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, .2);
}

.agm_signup_header {
  width: 100%;
  height: auto;
  padding: 60px 50px 60px 91px;
  background: transparent linear-gradient(89deg, #30679A 0%, #EAF5FE 100%) 0% 0% no-repeat padding-box;
  border-radius: 40px 40px 0px 0px;
  position: relative;
}

.agm_signup_header h4 {
  font-family: "MontserratBold";
  font-size: 25px;
  text-align: left;
  letter-spacing: 0;
  color: #FFFFFF;
  margin: 0;
}

.agm_signup_header span {
  color: #fff;
}

.agm_signup_body {
  background: #fff;
  border-radius: 0px 0px 40px 40px;
}

.agm_project_modals .agm_theme_btn {
  width: auto;
  height: 37px;
  background: #5A8BB9 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 5px #5A8BB94D !important;
  border-radius: 50px;
  color: #fff;
  font-family: "MontserratBold";
  float: right;
  font-size: 16px;
  border: 0px;
  transition: all .4s;
  cursor: pointer;
  margin: 0px !important;
}

.agm_project_modals .agm_theme_btn a {
  color: #fff;
  font-family: "MontserratBold";
}

.agm_project_modals .agm_theme_btn:focus {
  outline: none !important;
  border: 1px solid #30679A;
}

.agm_project_modals .agm_theme_btn:hover {
  background: #30679a !important;
}

.agm_project_modals .agm_modal_btn {
  float: left;
  border: 1px solid #30679A;
  background: #fff;
  box-shadow: none !important;
}

.agm_project_modals .agm_modal_btn {
  color: #5A8BB9;
  font-family: "Montserrat";
}

.agm_project_modals .agm_modal_btn:hover {
  color: #fff;
  ;
  box-shadow: 0px 5px 5px #5A8BB94D !important;
}

.agm_project_modals {
  width: 100%;
  height: auto;
  border-bottom: 1px solid #95989A;
  padding: 6px 0px 0px;
}

.agm_project_modals ul {
  padding: 0px 75px;
  width: 100%;
  height: auto;
  margin: 0;
  text-align: left;
}

.agm_project_modals ul li {
  list-style: none;
  display: inline-block;
  margin-right: 20px;
}

.agm_modal_btn i {
  background: #5a8bb9;
  color: #fff;
  padding: 5px 7px;
  border-radius: 50px;
  font-size: 14px;
}

.agm_modal_btn:hover i {
  background: #fff;
  color: #5a8bb9;
}

.agm_theme_btn.agm_sub {
  width: 100%;
  padding: 0px 10px;
}

.agm_project_scroll {
  width: 100%;
  height: auto;
}


.agm_project {
  text-align: left !important;
  padding: 0 !important;
  list-style: none;
}

.agm_project li {
  width: 100%;
  position: relative;
  border: 1px solid #D9D9D9;
  box-shadow: 0px 5px 5px #D9D9D94D;
  border-radius: 20px;
  margin-bottom: 7px;
}

.agm_projects_list {
  width: 100%;
  height: auto;
  position: relative;
  background: #fff;
  border-left: 14px solid #8EE952;
  border-radius: 20px;
  padding: 15px 0px;
  padding-left: 15px;
}

.agm_projects_list.agm_red {
  border-left: 14px solid #E95252 !important;
}

.agm_projects_list.agm_green {
  border-left: 14px solid #8EE952 !important;
}

.agm_projects_list.agm_orange {
  border-left: 14px solid #E9C152 !important;
}

.agm_projects_list i {
  background: #5A8BB9;
  color: #fff;
  padding: 5px 6px;
  border-radius: 50px;
  position: absolute;
  top: 8px;
  right: 15px;
  cursor: pointer;
}

.agm_projects_list h4 {
  font-size: 14px;
  font-family: "MontserratBold";
  margin: 0;
}

.agm_projects_list p {
  font-size: 12px;
  margin: 0;
}

.agm_signup_form label,
.agm_signup_form label u {
  margin: 0px;
  font-family: "MontserratBold";
  color: #000;
  font-size: 14px;
}

.agm_signup_form input {
  font-size: 16px;
  border: 0px;
  border-bottom: 1px solid #95989A;
  border-radius: 0px;
  padding: 5px 0px;
  color: #000;
  height: 24px;
}

.agm_signup_form .agm_remember {
  text-align: left;
  font-family: "Montserrat";
  font-size: 16px;
  letter-spacing: 0;
  color: #cccccc;
  padding: 5px 0px;
  ;
}

.agm_signup_form {
  padding: 8px 75px;
}

/* custom select */
.agm_select-box {
  position: relative;
  display: block;
  width: 100%;
  font-size: 16px;
  border: 0px;
  border-radius: 0px;
  padding: 5px 0px;
  color: #000;
  height: 24px;
}

.agm_select-box__current {
  position: relative;
  cursor: pointer;
  outline: none;
  border-bottom: 1px solid #95989A;
}

.agm_select-box__current:focus {
  border-bottom: 2px solid #30679A;
}

.agm_select-box__current:focus+.agm_select-box__list {
  opacity: 1;
  -webkit-animation-name: none;
  animation-name: none;
}

.agm_select-box__current:focus+.agm_select-box__list .agm_select-box__option {
  cursor: pointer;
}

.agm_select-box__current:focus .agm_select-box__icon {
  -webkit-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}

.agm_select-box__icon {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 14px;
  transition: 0.2s ease;
}

.agm_select-box__value {
  display: flex;
}

.agm_select-box__input {
  display: none;
}

.agm_select-box__input:checked+.agm_select-box__input-text {
  display: block;
}

.agm_select-box__input-text {
  display: none;
  width: 100%;
  margin: 0;
  background-color: #fff;
}

.agm_select-box__list {
  position: absolute;
  top: 35px;
  width: 100%;
  padding: 0;
  list-style: none;
  opacity: 0;
  -webkit-animation-name: HideList;
  animation-name: HideList;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: step-start;
  animation-timing-function: step-start;
  box-shadow: 0px 3px 6px #00000029;
}

.agm_select-box__list li label {
  font-family: "Montserrat";
  font-size: 18px;
  border-bottom: 1px solid #eee;
}

.agm_select-box__option {
  display: block;
  padding: 20px;
  background-color: #fff;
}

.agm_select-box__option:hover,
.agm_select-box__option:focus {
  color: #546c84;
  background-color: #fbfbfb;
}

@-webkit-keyframes HideList {
  from {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }

  to {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
}

@keyframes HideList {
  from {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }

  to {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
}

/* modal css */
.agm_modal .modal-content {
  border: 0px !important;
  background: none !important;
}

.agm_modal_closed {
  background: #5A8BB9;
  color: #e4f1fb;
  padding: 5px 8px 6px 7px;
  border-radius: 50px;
  position: absolute;
  top: 15px;
  right: 27px;
  cursor: pointer;
  z-index: 999;
}

.agm_modal .agm_signup_form {
  padding: 37px 75px;
}

/* bottom btn */
.agm_theme_btn {
  width: 123px;
  height: 37px;
  background: #5A8BB9 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 5px #5A8BB94D !important;
  border-radius: 50px;
  color: #fff;
  font-family: "MontserratBold";
  font-size: 18px;
  border: 0px;
  transition: all .4s;
  cursor: pointer;
  margin: 20px;
}

.agm_theme_btn a {
  color: #fff;
  font-family: "MontserratBold";
}

.agm_google_btn:hover,
.agm_fb_btn:hover,
.agm_google_btn:focus,
.agm_fb_btn:focus {
  box-shadow: 0px 7px 10px #929eaa;
}

.agm_theme_btn:focus {
  border: 1px solid #30679A;
  outline: none !important;
}

.agm_theme_btn:hover {
  background: #30679a !important;
}

.agm_back {
  float: left;
  border: 1px solid #30679A;
  background: #fff;
  box-shadow: none !important;
}

.agm_back {
  color: #5A8BB9;
  font-family: "Montserrat";
}

.agm_back:hover {
  color: #fff;
  font-family: "MontserratBold";
}

.agm_back:hover {
  box-shadow: 0px 5px 5px #5A8BB94D !important;
}

.agm_paste_input {
  height: 80px !important;
  border: 1px solid #D9D9D9 !important;
  padding: 0px 13px 30px 11px !important;
  font-size: 14px !important;
}

.agm_paste_input:focus {
  border: 1px solid #D9D9D9 !important;
}

.agm_paste_btn .input-group-text {
  background: #F8F8F8 !important;
  height: 80px !important;
  width: 80px !important;
  text-align: center !important;
  justify-content: center !important;
  cursor: pointer;
}

.agm_paste_input::placeholder {
  padding: 0px;
  color: #D9D9D9;
}

.agm_user_status {
  width: 100%;
  text-align: right;
  padding-right: 5px;
}

.agm_user_status span {
  font-size: 12px;
  padding: 0px 15px;
}

.agm_user_status span:first-child {
  border-right: 3px solid #30679A;
}

/* end */
/* custom radio/checkbox */
/* Customize the label (the container) */
.agm_container {
  position: relative;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-top: 0px;
  height: 16px;
}

/* Hide the browser's default checkbox */
.agm_container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.agm_checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.2rem;
  width: 1.2rem;
  border-radius: 50px;
  border: 1px solid #95989A;
  background: #fff;
}

.agm_checkmark.header_checkBox {
  left: -2.8px;
}

/* On mouse-over, add a grey background color */
.agm_container:hover input~.agm_checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.agm_container input:checked~.agm_checkmark {
  background-color: #346a9c !important;
  border-radius: 50px;
}

/* Create the agm_checkmark/indicator (hidden when not checked) */
.agm_checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the agm_checkmark when checked */
.agm_container input:checked~.agm_checkmark:after {
  display: block;
  border-color: #fff;
}

/* Style the agm_checkmark/indicator */
.agm_container .agm_checkmark:after {
  left: 5.5px;
  top: 3px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}


.agm_container.undo input:checked~.agm_checkmark:after {
  border-color: #30679A;
  display: block !important;
}

.agm_container.undo input:checked~.agm_checkmark {
  background-color: #EAF5FE !important;
}

/* end */
/* table css */
.agm_fixed_headers {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.agm_fixed_headers th,
.agm_fixed_headers td {
  padding: 5px;
  text-align: left;
  border: 0px;
}

.agm_fixed_headers td:nth-child(1),
.agm_fixed_headers th:nth-child(1) {
  width: 150px;
  border: 0px;
  text-align: right;
}

.agm_fixed_headers td:nth-child(2),
.agm_fixed_headers th:nth-child(2) {
  width: 32em;
  border: 0px;
  text-align: center;
}

.agm_fixed_headers td:nth-child(3),
.agm_fixed_headers th:nth-child(3) {
  width: 50%;
  border: 0px;
  text-align: left;
}

.agm_fixed_headers thead {
  background-color: #30679A;
  color: #fff;
}

.agm_fixed_headers thead tr {
  display: block;
  position: relative;
}

.agm_fixed_headers tbody {
  display: block;
  overflow: auto;
  width: 100%;
  height: 300px;
  color: #000;
}

.agm_fixed_headers tbody::-webkit-scrollbar-track {
  background-color: #EAF5FE;
}

.agm_fixed_headers tbody::-webkit-scrollbar {
  width: 12px;
  background-color: #5A8BB9;
}

/* Buttons */
.agm_fixed_headers tbody::-webkit-scrollbar-button:single-button {
  background-color: #5A8BB9;
  display: block;
  border-style: solid;
  height: 15px;
  width: 16px;
}

/* Up */
.agm_fixed_headers tbody::-webkit-scrollbar-button:single-button:vertical:decrement {
  border-width: 0 6px 6px 6px;
  border-color: transparent transparent #fff transparent;
}

.agm_fixed_headers tbody::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  border-color: transparent transparent #fff transparent;
}

/* Down */
.agm_fixed_headers tbody::-webkit-scrollbar-button:single-button:vertical:increment {
  border-width: 6px 6px 0 6px;
  border-color: #fff transparent transparent transparent;
}

.agm_fixed_headers tbody::-webkit-scrollbar-button:vertical:single-button:increment:hover {
  border-color: #000 transparent transparent transparent;
}

.agm_fixed_headers tbody::-webkit-scrollbar-thumb {
  background-color: #5A8BB9;
  border: 1px solid #30679A;
}


.agm_fixed_headers tbody tr:nth-child(even) {
  background-color: #fff !important;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #FCFCFC !important;
}

.old_ie_wrapper {
  height: 300px;
  width: 750px;
  overflow-x: hidden;
  overflow-y: auto;
}

.old_ie_wrapper tbody {
  height: auto;
}

.pxl-5 {
  padding: 1.5em;
}

/* end */














@media screen and (min-width:1600px) {
  .agm_fixed_headers tbody tr th[scope="row"] {
    padding: 6px 22px;
  }
}

@media (min-width:1367px) and (max-width:1599px) {
  .agm_fixed_headers tbody tr th[scope="row"] {
    padding: 6px 20px;
  }
}

@media screen and (max-width:1366) {
  .agm_fixed_headers tbody tr th[scope="row"] {
    padding: 6px 10px;
  }
}

@media screen and (max-width:767) {
  .agm_checkmark {
    width: 1rem;
    height: 1rem;
  }

  .agm_container .agm_checkmark:after {
    left: 4.8px;
    top: 2px;
    width: 5px;
    height: 9px;
  }
}


/* wide screen end */
@media (min-width:992px) and (max-width:1900px) {
  .agm_form_mx_width {
    max-width: 540px;
  }

  .pxl-5 {
    padding: 0px;
  }

  .agm_container.agm_remember .agm_checkmark {
    top: 2px !important;
    height: 16px;
    width: 16px;
  }

  .agm_container.agm_remember .agm_checkmark:after {
    left: 5px;
    top: 3px;
    width: 4px;
    height: 7px;
    border-width: 0 2px 2px 0;
  }

  .agm_signup_form .agm_remember {
    margin-bottom: 5px !important;
  }

  .agm_signup_header span {
    font-size: 12px;
  }

  .agm_fixed_headers tbody {
    height: 253px;
  }

  .agm_fixed_headers thead th,
  .agm_fixed_headers tbody tr td,
  .agm_fixed_headers tbody tr th {
    padding: 6px 12px;
  }

  .agm_fixed_headers {
    font-size: 10px;
  }

  .agm_theme_btn {
    margin: 10px 0px 20px;
    width: 80px;
    height: 26px;
    font-size: 9px;
    padding: 3px 5px;
  }

  .agm_signup_header h4 {
    font-size: 14px;
  }

  img[alt="Logo"] {
    width: 60px !important;
  }

  .agm_signup_form label {
    font-size: 9px;
  }

  .agm_signup_form input {
    font-size: 9px;
  }

  .agm_signup_form .remember {
    font-size: 12px;
  }

  .agm_google_btn,
  .agm_fb_btn {
    font-size: 14px;
    padding: 6px 17px;
  }

  .agm_project_modals .agm_theme_btn {
    width: 100%;
    height: 30px;
    font-size: 12px;
  }

  .agm_modal_btn i {
    background: #5a8bb9;
    color: #fff;
    padding: 4px 5px;
    border-radius: 50px;
    font-size: 10px;
    margin-right: 3px;
  }

  .agm_theme_btn.agm_sub {
    width: 100%;
  }

  .agm_project_modals ul {
    padding: 0px 70px;
  }

  .agm_project_modals ul li {
    margin-right: 10px;
  }

  .agm_signup_header {
    padding: 32px 37px 32px 55px;
    border-radius: 20px 20px 0px 0px;
  }

  .agm_signup_body {
    border-radius: 0px 0px 20px 20px;
    padding: 0px;
    margin-top: 0px;
  }

  .agm_signup_form {
    padding: 8px 70px;
  }

  .agm_signup_form .agm_remember {
    font-size: 14px;
    padding-top: 10px;
  }

  .agm_width-selector-inner__control,
  .agm_width-selector-inner__menu-list .agm_width-selector-inner__option {
    font-size: 14px;
  }

  .agm_project_scroll {
    max-height: 300px;

  }

  .agm_projects_list h4,
  .agm_projects_list i {
    font-size: 12px;
  }

  .agm_projects_list p {
    font-size: 10px;
  }

  .agm_project li {
    border-radius: 10px;
  }

  .agm_projects_list.agm_red {
    border-left: 10px solid #E95252 !important;
  }

  .agm_projects_list.agm_orange {
    border-left: 10px solid #E9C152 !important;
  }

  .agm_projects_list {
    border-left: 10px solid #8EE952;
    border-radius: 10px;
    padding: 10px 0px;
    padding-left: 5px;
  }

  .agm_user_label {
    font-size: 10px !important;
    left: -9px !important;
    top: 42% !important;
  }

  .agm_user_status span {
    font-size: 8px;
  }

  .agm_user_status {
    line-height: 0px;
  }

  .agm_google_btn img,
  .agm_fb_btn img {
    width: 20px !important;
  }

  .agm_hidden_pass {
    width: 15px;
  }

  .agm_signup_form .form-group {
    margin-bottom: 0px;
  }

  .custom-control {
    margin-top: 15px;
  }

  .agm_signup_form_main {
    margin: 30px 0px;
  }
}

/* wide screen end */

/* wide screen end */
@media (min-width:1601px) and (max-width:1920px) {
  .agm_checkmark.header_checkBox {
    left: -1.9px;
  }
}

@media (min-width:1401px) and (max-width:1600px) {
  .agm_checkmark.header_checkBox {
    left: -2.3px;
  }

}

@media (min-width:1301px) and (max-width:1500px) {
  .agm_checkmark.header_checkBox {
    left: -2.6px;
  }
}

@media (min-width:1150px) and (max-width:1300px) {
  .agm_checkmark.header_checkBox {
    left: -1.2px;
  }
}

@media (min-width:992px) and (max-width:1149px) {
  .agm_checkmark.header_checkBox {
    left: -1px;
  }
}

@media (min-width:851px) and (max-width:991px) {
  .agm_checkmark.header_checkBox {
    left: -2.3px;
  }
}

@media (min-width:768px) and (max-width:850px) {
  .agm_checkmark.header_checkBox {
    left: -1.2px;
  }
}

@media (min-width:450px) and (max-width:767px) {
  .agm_checkmark.header_checkBox {
    left: -2.3px;
  }
}

/* desktop screen */
@media (min-width:768px) and (max-width:991px) {

  .agm_form_mx_width {
    max-width: 540px;
  }

  .pxl-5 {
    padding: 0px;
  }

  .agm_signup_header span {
    font-size: 12px;
  }

  .agm_fixed_headers tbody {
    height: 253px;
  }

  .agm_fixed_headers thead th,
  .agm_fixed_headers tbody tr td,
  .agm_fixed_headers tbody tr th {
    padding: 6px 12px;
  }

  .agm_fixed_headers {
    font-size: 10px;
  }

  .agm_theme_btn {
    margin: 10px 0px 20px;
    width: 80px;
    height: 26px;
    font-size: 9px;
    padding: 3px 5px;
  }

  .agm_signup_header h4 {
    font-size: 14px;
  }

  img[alt="Logo"] {
    width: 60px !important;
  }

  .agm_signup_form label {
    font-size: 9px;
  }

  .agm_signup_form input {
    font-size: 9px;
  }

  .agm_signup_form .remember {
    font-size: 12px;
  }

  .agm_google_btn,
  .agm_fb_btn {
    font-size: 14px;
    padding: 6px 17px;
  }

  .agm_project_modals .agm_theme_btn {
    width: 100%;
    height: 30px;
    font-size: 12px;
  }

  .agm_modal_btn i {
    background: #5a8bb9;
    color: #fff;
    padding: 4px 5px;
    border-radius: 50px;
    font-size: 10px;
    margin-right: 3px;
  }

  .agm_theme_btn.agm_sub {
    width: 100%;
  }

  .agm_project_modals ul {
    padding: 0px 70px;
  }

  .agm_project_modals ul li {
    margin-right: 10px;
  }

  .agm_signup_header {
    padding: 32px 37px 32px 55px;
    border-radius: 20px 20px 0px 0px;
  }

  .agm_signup_body {
    border-radius: 0px 0px 20px 20px;
    padding: 0px;
    margin-top: 0px;
  }

  .agm_signup_form {
    padding: 8px 70px;
  }

  .agm_signup_form .agm_remember {
    font-size: 14px;
    padding-top: 10px;
  }

  .agm_width-selector-inner__control,
  .agm_width-selector-inner__menu-list .agm_width-selector-inner__option {
    font-size: 14px;
  }

  .agm_project_scroll {
    max-height: 300px;

  }

  .agm_projects_list h4,
  .agm_projects_list i {
    font-size: 12px;
  }

  .agm_projects_list p {
    font-size: 10px;
  }

  .agm_project li {
    border-radius: 10px;
  }

  .agm_projects_list.agm_red {
    border-left: 10px solid #E95252 !important;
  }

  .agm_projects_list.agm_orange {
    border-left: 10px solid #E9C152 !important;
  }

  .agm_projects_list {
    border-left: 10px solid #8EE952;
    border-radius: 10px;
    padding: 10px 0px;
    padding-left: 5px;
  }

  .agm_user_label {
    font-size: 10px !important;
    left: -9px !important;
    top: 42% !important;
  }

  .agm_user_status {
    line-height: 0px;
  }

  .agm_user_status span {
    font-size: 8px;
  }

  .agm_google_btn img,
  .agm_fb_btn img {
    width: 18px !important;
  }

  .agm_hidden_pass {
    width: 15px;
  }

  .agm_signup_form .form-group {
    margin-bottom: 0px;
  }

  .custom-control {
    margin-top: 15px;
  }

  .agm_signup_form_main {
    margin: 30px 0px;
  }  

  .agm_checkmark.header_checkBox {
    top: 2px;
    height: 1rem;
    width: 1rem;
  }

  .agm_checkmark {
    top: 2px;
    height: 1rem;
    width: 1rem;
  }
}

/* desktop screen end */
/* tab screen */
@media (min-width:567px) and (max-width:768px) {

  .agm_form_mx_width {
    max-width: 540px;
  }

  .pxl-5 {
    padding: 0px;
  }

  .agm_signup_header span {
    font-size: 12px;
  }

  .agm_fixed_headers tbody {
    height: 253px;
  }

  .agm_fixed_headers thead th,
  .agm_fixed_headers tbody tr td,
  .agm_fixed_headers tbody tr th {
    padding: 6px 12px;
  }

  .agm_fixed_headers {
    font-size: 10px;
  }

  .agm_theme_btn {
    margin: 10px 0px 20px;
    width: 80px;
    height: 26px;
    font-size: 9px;
    padding: 3px 5px;
  }

  .agm_signup_header h4 {
    font-size: 14px;
  }

  img[alt="Logo"] {
    width: 60px !important;
  }

  .agm_signup_form label {
    font-size: 9px;
  }

  .agm_signup_form input {
    font-size: 9px;
  }

  .agm_signup_form .remember {
    font-size: 12px;
  }

  .agm_google_btn,
  .agm_fb_btn {
    font-size: 14px;
    padding: 6px 17px;
  }

  .agm_project_modals .agm_theme_btn {
    width: 100%;
    height: 30px;
    font-size: 12px;
    ;
  }

  .agm_modal_btn i {
    background: #5a8bb9;
    color: #fff;
    padding: 4px 5px;
    border-radius: 50px;
    font-size: 10px;
    margin-right: 3px;
  }

  .agm_theme_btn.agm_sub {
    width: 100%;
  }

  .agm_project_modals ul {
    padding: 0px 70px;
  }

  .agm_project_modals ul li {
    margin-right: 10px;
  }

  .agm_signup_header {
    padding: 32px 37px 32px 55px;
    border-radius: 20px 20px 0px 0px;
  }

  .agm_signup_body {
    border-radius: 0px 0px 20px 20px;
    padding: 0px;
    margin-top: 0px;
  }

  .agm_signup_form {
    padding: 8px 70px;
  }

  .agm_signup_form .agm_remember {
    font-size: 14px;
    padding-top: 10px;
  }

  .agm_width-selector-inner__control,
  .agm_width-selector-inner__menu-list .agm_width-selector-inner__option {
    font-size: 14px;
  }

  .agm_project_scroll {
    max-height: 300px;

  }

  .agm_projects_list h4,
  .agm_projects_list i {
    font-size: 12px;
  }

  .agm_projects_list p {
    font-size: 10px;
  }

  .agm_project li {
    border-radius: 10px;
  }

  .agm_projects_list.agm_red {
    border-left: 10px solid #E95252 !important;
  }

  .agm_projects_list.agm_orange {
    border-left: 10px solid #E9C152 !important;
  }

  .agm_projects_list {
    border-left: 10px solid #8EE952;
    border-radius: 10px;
    padding: 10px 0px;
    padding-left: 5px;
  }

  .agm_user_label {
    font-size: 10px !important;
    left: -9px !important;
    top: 42% !important;
  }

  .agm_user_status {
    line-height: 0px;
  }

  .agm_user_status span {
    font-size: 8px;
  }

  .agm_google_btn img,
  .agm_fb_btn img {
    width: 18px !important;
  }

  .agm_hidden_pass {
    width: 15px;
  }

  .agm_signup_form .form-group {
    margin-bottom: 0px;
  }

  .custom-control {
    margin-top: 15px;
  }

  .agm_checkmark.header_checkBox {
    top: 2px;
    height: 1rem;
    width: 1rem;
  }

  .agm_checkmark {
    top: 2px;
    height: 1rem;
    width: 1rem;
  }
}

/* tab screen end */
/* mobile screen */
@media (min-width:0px) and (max-width:567px) {


  .agm_form_mx_width {
    max-width: 540px;
  }

  .pxl-5 {
    padding: 0px;
  }

  .agm_signup_header span {
    font-size: 12px;
  }

  .agm_fixed_headers tbody {
    height: 253px;
  }

  .agm_fixed_headers thead th,
  .agm_fixed_headers tbody tr td,
  .agm_fixed_headers tbody tr th {
    padding: 6px 12px;
  }

  .agm_fixed_headers {
    font-size: 10px;
  }

  .agm_theme_btn {
    margin: 10px 0px 20px;
    width: 80px;
    height: 26px;
    font-size: 9px;
    padding: 3px 5px;
  }

  .agm_signup_header h4 {
    font-size: 14px;
  }

  img[alt="Logo"] {
    width: 60px !important;
  }

  .agm_signup_form label {
    font-size: 9px;
  }

  .agm_signup_form input {
    font-size: 9px;
  }

  .agm_signup_form .remember {
    font-size: 12px;
  }

  .agm_google_btn,
  .agm_fb_btn {
    font-size: 14px;
    padding: 6px 17px;
  }

  .agm_project_modals .agm_theme_btn {
    width: 100%;
    height: 30px;
    font-size: 12px;
  }

  .agm_modal_btn i {
    background: #5a8bb9;
    color: #fff;
    padding: 4px 5px;
    border-radius: 50px;
    font-size: 10px;
    margin-right: 3px;
  }

  .agm_theme_btn.agm_sub {
    width: 100%;
  }

  .agm_project_modals ul {
    padding: 0px 40px;
  }

  .agm_project_modals ul li {
    margin-right: 10px;
  }

  .agm_signup_header {
    padding: 32px 37px 32px 55px;
    border-radius: 20px 20px 0px 0px;
  }

  .agm_signup_body {
    border-radius: 0px 0px 20px 20px;
    padding: 0px;
    margin-top: 0px;
  }

  .agm_signup_form {
    padding: 8px 40px;
  }

  .agm_signup_form .agm_remember {
    font-size: 14px;
    padding-top: 10px;
  }

  .agm_width-selector-inner__control,
  .agm_width-selector-inner__menu-list .agm_width-selector-inner__option {
    font-size: 14px;
  }

  .agm_project_scroll {
    max-height: 300px;

  }

  .agm_projects_list h4,
  .agm_projects_list i {
    font-size: 12px;
  }

  .agm_projects_list p {
    font-size: 10px;
  }

  .agm_project li {
    border-radius: 10px;
  }

  .agm_projects_list.agm_red {
    border-left: 10px solid #E95252 !important;
  }

  .agm_projects_list.agm_orange {
    border-left: 10px solid #E9C152 !important;
  }

  .agm_projects_list {
    border-left: 10px solid #8EE952;
    border-radius: 10px;
    padding: 10px 0px;
    padding-left: 5px;
  }

  .agm_user_label {
    font-size: 10px !important;
    left: -9px !important;
    top: 42% !important;
  }

  .agm_user_status {
    line-height: 0px;
  }

  .agm_user_status span {
    font-size: 8px;
  }

  .agm_google_btn img,
  .agm_fb_btn img {
    width: 18px !important;
  }

  .agm_hidden_pass {
    width: 15px;
  }

  .agm_signup_form .form-group {
    margin-bottom: 0px;
  }

  .custom-control {
    margin-top: 15px;
  }

  .agm_order-xs-2 {
    order: 2;
    text-align: center;
    font-size: 12px;
  }

  .agm_order-xs-2 h4 {
    text-align: center;
  }

  .agm_order-xs-1 {
    order: 1;
    text-align: center;
  }

  .agm_order-xs-1 img {
    float: initial !important;
    margin-bottom: 10px;
  }

  .agm_signup_header {
    padding: 32px 37px 32px 37px;
  }

  .agm_checkmark.header_checkBox {
    top: 2px;
    height: 1rem;
    width: 1rem;
  }

  .agm_checkmark {
    top: 2px;
    height: 1rem;
    width: 1rem;
  }

}

@media (min-width:0px) and (max-width:400px) {
  .pxl-5 {
    padding: 0px;
  }

  .agm_signup_header span {
    font-size: 12px;
  }

  .agm_order-xs-2 {
    order: 2;
    text-align: center;
    font-size: 12px;
  }

  .agm_order-xs-2 h4 {
    text-align: center;
  }

  .agm_order-xs-1 {
    order: 1;
    text-align: center;
  }

  .agm_order-xs-1 img {
    float: initial !important;
    margin-bottom: 10px;
  }

  .agm_signup_header {
    padding: 32px 37px 32px 37px;
  }

  .agm_signup_body {
    padding: 0px;
  }
}



/* mobile screen end */