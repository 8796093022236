body {
  min-height: 100vh;
  margin: 0 auto;
  padding: 0;
  background: transparent linear-gradient(241deg, #5A8BB9 0%, #ECF6FF 100%) 0% 0% no-repeat padding-box;
  opacity: 1;

}

* {
  font-family: "Montserrat";
  font-weight: 400;
}

a,
a:hover {
  text-decoration: none;
  color: #fff;
}

.no-padding {
  padding: 0px !important;
}

.no-margin {
  margin: 0px !important;
}

input:focus {
  outline: 0px !important;
  border-color: #000 !important;
  box-shadow: none !important;
}

input::placeholder {
  color: #95989A;
}

.au_form_mx_width {
  max-width: 868px;
  margin: 0 auto;
}

.au_main_wrapper {
  width: 100%;
}

.au_signup_form_main {
  width: 100%;
  height: auto;
  background: #fff;
  border-radius: 40px;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, .2);
}

.au_signup_header {
  width: 100%;
  height: auto;
  padding: 60px 50px 60px 91px;
  background: transparent linear-gradient(89deg, #30679A 0%, #EAF5FE 100%) 0% 0% no-repeat padding-box;
  border-radius: 40px 40px 0px 0px;
  position: relative;
}

.au_signup_header h4 {
  font-family: "MontserratBold";
  font-size: 25px;
  text-align: left;
  letter-spacing: 0;
  color: #FFFFFF;
  margin: 0;
}

.au_signup_body {
  background: #fff;
  border-radius: 0px 0px 40px 40px;
}

.au_project_modals .au_theme_btn {
  width: 100%;
  height: 37px;
  background: #5A8BB9 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 5px #5A8BB94D !important;
  border-radius: 50px;
  color: #fff;
  font-family: "MontserratBold";
  float: right;
  font-size: 16px;
  border: 0px;
  transition: all .4s;
  cursor: pointer;
}

.au_project_modals .au_theme_btn a {
  color: #fff;
  font-family: "MontserratBold";
}

.au_project_modals .au_theme_btn:focus {
  outline: none !important;
  border: 1px solid #30679A;
}

.au_project_modals .au_theme_btn:hover {
  background: #30679a !important;
}

.au_project_modals .au_modal_btn {
  float: left;
  border: 1px solid #30679A;
  background: #fff;
  box-shadow: none !important;
}

.au_project_modals .au_modal_btn {
  color: #5A8BB9;
  font-family: "Montserrat";
}

.au_project_modals .au_modal_btn:hover {
  color: #fff;
  ;
  box-shadow: 0px 5px 5px #5A8BB94D !important;
}

.au_project_modals {
  width: 100%;
  height: auto;
  border-bottom: 1px solid #95989A;
  padding: 6px 0px 0px;
}

.au_project_modals ul {
  padding: 0px 50px;
  width: 100%;
  height: auto;
  margin: 0;
  text-align: center;
}

.au_project_modals ul li {
  list-style: none;
  display: inline-block;
  margin-right: 20px;
}

.au_modal_btn i {
  background: #5a8bb9;
  color: #fff;
  padding: 5px 7px;
  border-radius: 50px;
  font-size: 14px;
}

.au_modal_btn:hover i {
  background: #fff;
  color: #5a8bb9;
}

.au_theme_btn.au_sub {
  width: 100%;
  padding: 0px 10px;
}

.au_project_scroll {
  width: 100%;
  max-height: 308px;
  overflow-y: scroll;
  padding: 8px 75px;
  border-bottom: 1px solid #95989A;
}

.au_project_scroll::-webkit-scrollbar-track {
  background-color: #EAF5FE;
}

.au_project_scroll::-webkit-scrollbar {
  width: 12px;
  background-color: #5A8BB9;
}

/* Buttons */
.au_project_scroll::-webkit-scrollbar-button:single-button {
  background-color: #5A8BB9;
  display: block;
  border-style: solid;
  height: 15px;
  width: 16px;
}

/* Up */
.au_project_scroll::-webkit-scrollbar-button:single-button:vertical:decrement {
  border-width: 0 6px 6px 6px;
  border-color: transparent transparent #fff transparent;
}

.au_project_scroll::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  border-color: transparent transparent #fff transparent;
}

/* Down */
.au_project_scroll::-webkit-scrollbar-button:single-button:vertical:increment {
  border-width: 6px 6px 0 6px;
  border-color: #fff transparent transparent transparent;
}

.au_project_scroll::-webkit-scrollbar-button:vertical:single-button:increment:hover {
  border-color: #000 transparent transparent transparent;
}

.au_project_scroll::-webkit-scrollbar-thumb {
  background-color: #5A8BB9;
  border: 1px solid #30679A;
}

.au_project {
  text-align: left !important;
  padding: 0 !important;
  list-style: none;
}

.au_project li {
  width: 100%;
  position: relative;
  border: 1px solid #D9D9D9;
  box-shadow: 0px 5px 5px #D9D9D94D;
  border-radius: 20px;
  margin-bottom: 7px;
}

.au_projects_list {
  width: 100%;
  height: auto;
  background: #fff;
  border-left: 14px solid #8EE952;
  border-radius: 20px;
  padding: 30px 0px;
  padding-left: 15px;
}

.au_projects_list.au_red {
  border-left: 14px solid #E95252 !important;
}

.au_projects_list i {
  background: #5A8BB9;
  color: #fff;
  padding: 5px 6px;
  border-radius: 50px;
  position: absolute;
  top: 8px;
  right: 15px;
  cursor: pointer;
}

.au_projects_list h4 {
  font-size: 14px;
  font-family: "MontserratBold";
  margin: 0;
}

.au_projects_list p {
  font-size: 12px;
  margin: 0;
}

.au_signup_form label,
.au_signup_form label u {
  margin: 0px;
  font-family: "MontserratBold";
  color: #000;
  font-size: 14px;
  top: 17px;
}

.au_signup_form input {
  font-size: 16px;
  border: 0px;
  border-bottom: 1px solid #95989A;
  border-radius: 0px;
  padding: 5px 0px;
  color: #000;
  height: 24px;
}

.au_signup_form .au_remember {
  text-align: left;
  font-family: "Montserrat";
  font-size: 16px;
  letter-spacing: 0;
  color: #cccccc;
  padding: 5px 0px;
  ;
}

.au_signup_form {
  padding: 8px 75px;
}

/* custom select */
.au_select-box {
  position: relative;
  display: block;
  width: 100%;
  font-size: 16px;
  border: 0px;
  border-radius: 0px;
  padding: 5px 0px;
  color: #000;
  height: 24px;
}

.au_select-box__current {
  position: relative;
  cursor: pointer;
  outline: none;
  border-bottom: 1px solid #95989A;
}

.au_select-box__current:focus {
  border-bottom: 2px solid #30679A;
}

.au_select-box__current:focus+.au_select-box__list {
  opacity: 1;
  -webkit-animation-name: none;
  animation-name: none;
}

.au_select-box__current:focus+.au_select-box__list .au_select-box__option {
  cursor: pointer;
}

.au_select-box__current:focus .au_select-box__icon {
  -webkit-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}

.au_select-box__icon {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 14px;
  transition: 0.2s ease;
}

.au_select-box__value {
  display: flex;
}

.au_select-box__input {
  display: none;
}

.au_select-box__input:checked+.au_select-box__input-text {
  display: block;
}

.au_select-box__input-text {
  display: none;
  width: 100%;
  margin: 0;
  background-color: #fff;
}

.au_select-box__list {
  position: absolute;
  top: 35px;
  width: 100%;
  padding: 0;
  list-style: none;
  opacity: 0;
  -webkit-animation-name: HideList;
  animation-name: HideList;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: step-start;
  animation-timing-function: step-start;
  box-shadow: 0px 3px 6px #00000029;
}

.au_select-box__list li label {
  font-family: "Montserrat";
  font-size: 18px;
  border-bottom: 1px solid #eee;
}

.au_select-box__option {
  display: block;
  padding: 20px;
  background-color: #fff;
}

.au_select-box__option:hover,
.au_select-box__option:focus {
  color: #546c84;
  background-color: #fbfbfb;
}

@-webkit-keyframes HideList {
  from {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }

  to {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
}

@keyframes HideList {
  from {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }

  to {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
}

.au_select {
  position: relative;
}

.au_custom_select {
  font-size: 16px;
  border-radius: 0;
  border: none;
  background: transparent;
  width: 100%;
  overflow: hidden;
  padding: 0;
  height: auto;
  color: #000;
  border-bottom: 1px solid #95989A;
  appearance: none;
  -webkit-appearance: none
}

.au_custom_select:focus {
  outline: none;
  box-shadow: none;
  border-bottom: 2px solid #30679A;
}

.au_custom_select optgroup {
  font-size: 16px;
  background: #fff;
  padding: 115px 0px;
}

.au_custom_caret:after {
  position: absolute;
  bottom: 18px;
  right: 10px;
  width: 0;
  height: 0;
  padding: 0;
  content: '';
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #30679A;
  pointer-events: none;
}

.au_custom_select:focus .au_custom_caret:after {
  transform: rotate(180deg) !important;
  display: none !important;
}

/* modal css */
.au_modal .modal-content {
  border: 0px !important;
  background: none !important;
}

.au_modal_closed {
  background: #5A8BB9;
  color: #e4f1fb;
  padding: 5px 8px 6px 7px;
  border-radius: 50px;
  position: absolute;
  top: 15px;
  right: 27px;
  cursor: pointer;
  z-index: 999;
}

.au_modal .au_signup_form {
  padding: 60px 150px 60px;
  width: 100%;
  float: left;
}

/* bottom btn */
.au_theme_btn {
  width: 123px;
  height: 37px;
  background: #5A8BB9 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 5px #5A8BB94D !important;
  border-radius: 50px;
  color: #fff;
  font-family: "MontserratBold";
  float: right;
  font-size: 18px;
  border: 0px;
  transition: all .4s;
  cursor: pointer;
  margin: 40px 0px;
}

.au_theme_btn a {
  color: #fff;
  font-family: "MontserratBold";
}

.au_google_btn:hover,
.au_fb_btn:hover,
.au_google_btn:focus,
.au_fb_btn:focus {
  box-shadow: 0px 7px 10px #929eaa;
}

.au_theme_btn:focus {
  border: 1px solid #30679A;
  outline: none !important;
}

.au_theme_btn:hover {
  background: #30679a !important;
}

.au_back {
  float: left;
  border: 1px solid #30679A;
  background: #fff;
  box-shadow: none !important;
  margin-bottom: 20px;
}

.au_back {
  color: #5A8BB9;
  font-family: "Montserrat";
}

.au_back:hover {
  color: #fff;
  font-family: "MontserratBold";
}

.au_back:hover {
  box-shadow: 0px 5px 5px #5A8BB94D !important;
}

.au_form_upload_message p {
  height: 80px !important;
  border: 1px solid #D9D9D9 !important;
  padding: 10px 13px 30px 11px !important;
  font-size: 14px !important;
  cursor: pointer;
  margin-bottom: 0;
  color: #d9e0ea;
  position: relative;
  z-index: 9;
}

.au_form_upload_message:focus,
.au_form_upload_message>div:focus,
.au_form_upload_message p:focus {
  border: 0px;
  outline: none;
}

.au_paste_btn {
  position: absolute;
  right: 0;
  z-index: 0;
}

.au_paste_input:focus {
  border: 1px solid #D9D9D9 !important;
}

.au_paste_btn .input-group-text {
  background: #F8F8F8 !important;
  height: 80px !important;
  width: 80px !important;
  text-align: center !important;
  justify-content: center !important;
  cursor: pointer;
  border-right: 0px;
}

.au_paste_input::placeholder {
  padding: 0px;
  color: #D9D9D9;
}

/* custon css of multi selector */
.au_select .dropdown-heading {
  padding: 5px 0px 5px 10px;
  border-left: 0px !important;
  border-right: 0px !important;
  border-top: 0px !important;
  border-color: #95989A !important;
  border-radius: 0px !important;
}

.au_select .select-panel div input {
  display: none !important;
}

.au_select .dropdown-content {
  height: auto;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-top: 3px solid #30679A !important;
  border: 0px;
  z-index: 9 !important;
}

.au_select .item-renderer input {
  position: absolute;
  opacity: 0;
}

.au_select .item-renderer span {
  padding-left: 30px !important;
}

.au_select .item-renderer input+span {
  position: relative;
  cursor: pointer;
  padding: 0;
  color: #000;
  font-size: 16px;
}

.au_select .item-renderer input+span:before {
  content: '';
  position: absolute;
  top: 3;
  left: 0;
  height: 1.2rem;
  width: 1.2rem;
  border-radius: 50px;
  border: 1px solid #95989A;
  background: #fff;
}

/* .item-renderer input:hover + span:before {
  background: #f35429;
} */
.au_select .item-renderer input:focus+span:before {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}

.au_select .item-renderer input:checked+span:before {
  background-color: #346a9c;
  border-radius: 50px;
}

.au_select .item-renderer input:disabled+span {
  color: #b8b8b8;
  cursor: auto;
}

.au_select .item-renderer input:disabled+span:before {
  box-shadow: none;
  background: #ddd;
}

.au_select .item-renderer input:checked+span:after {
  content: '';
  position: absolute;
  left: 7px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.au_select .dropdown-content {
  max-height: 200px !important;
}

.au_select .dropdown-content::-webkit-scrollbar-track {
  background-color: #EAF5FE;
}

.au_select .dropdown-content::-webkit-scrollbar {
  width: 12px;
  background-color: #5A8BB9;
}

/* Buttons */
.au_select .dropdown-content::-webkit-scrollbar-button:single-button {
  background-color: #5A8BB9;
  display: block;
  border-style: solid;
  height: 15px;
  width: 16px;
}

/* Up */
.au_select .dropdown-content::-webkit-scrollbar-button:single-button:vertical:decrement {
  border-width: 0 6px 6px 6px;
  border-color: transparent transparent #fff transparent;
}

.au_select .dropdown-content::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  border-color: transparent transparent #fff transparent;
}

/* Down */
.au_select .dropdown-content::-webkit-scrollbar-button:single-button:vertical:increment {
  border-width: 6px 6px 0 6px;
  border-color: #fff transparent transparent transparent;
}

.au_select .dropdown-content::-webkit-scrollbar-button:vertical:single-button:increment:hover {
  border-color: #000 transparent transparent transparent;
}

.au_select .dropdown-content::-webkit-scrollbar-thumb {
  background-color: #5A8BB9;
  border: 1px solid #30679A;
}

.au_select .multi-select .dropdown[aria-expanded="false"] .dropdown-heading-dropdown-arrow span {
  transform: rotate(180deg) !important;
}

.au_select .dropdown-heading-dropdown-arrow span {
  border-color: transparent transparent rgb(48, 103, 154) !important;
  border-width: 0px 6px 6px !important;
}

.au_select .select-panel .select-item:hover {
  background-color: rgb(241, 241, 241) !important;
}

.au_select .dropdown-heading-value,
.dropdown-heading {
  padding-left: 0px !important;
}

/* custon css of multi selector end */
/* end */

.form-group.au_select.add-button-div {
  display: block;
  width: 100%;
  height: 1px;
}

.form-group.au_select.add-button-div button[type="button"] {
  float: right;
  margin: 0px;
}



@media (min-width: 1901px) {
  .au_signup_form label {
    top: 24px;
  }
}

/* wide screen end */
@media (min-width:992px) and (max-width:1900px) {
  .au_form_mx_width {
    max-width: 540px;
  }

  .au_custom_caret:after {
    bottom: 10px;
  }

  .au_select .dropdown-heading-value,
  .dropdown-heading {
    font-size: 9px;
    line-height: 18px !important;
    height: 20px !important;
  }

  .au_select .dropdown-heading {
    line-height: 10px !important;
  }

  .au_custom_select,
  .au_select .item-renderer input+span {
    font-size: 9px !important;
  }

  .au_select .item-renderer input+span:before {
    top: 0px !important;
    height: 16px;
    width: 16px;
  }

  .au_select .item-renderer input:checked+span:after {
    left: 6px;
    top: 4px;
    width: 4px;
    height: 7px;
    border-width: 0 2px 2px 0;
  }

  .au_form_upload_message p {
    height: 50px !important;
    font-size: 9px !important;
    height: 24px !important;
  }

  .au_paste_btn .input-group-text {
    height: 42px !important;
    width: 42px !important;
    padding: 4px !important;
  }

  .au_theme_btn {
    margin: 30px 0 0px;
  }

  .au_signup_header h4 {
    font-size: 14px;
  }

  img[alt="Logo"] {
    width: 60px !important;
  }

  .au_signup_form label,
  .au_signup_form label,
  .au_signup_form label u {
    font-size: 9px;
  }

  .au_signup_form input {
    font-size: 9px;
    padding: 2px 0px 5px;
    height: 15px;
  }

  .au_signup_form .remember {
    font-size: 12px;
  }

  .au_google_btn,
  .au_fb_btn {
    font-size: 14px;
    padding: 6px 17px;
  }

  .au_modal .au_signup_form {
    padding: 37px 90px !important;
  }

  .au_theme_btn {
    width: 70px;
    height: 24px;
    font-size: 11px;
  }

  .al_paste_btn .input-group-text {
    height: 60px !important;
    width: 60px !important;
  }

  .al_signup_header {
    padding: 32px 37px 32px 55px;
    border-radius: 20px 20px 0px 0px;
  }

  .au_signup_header {
    padding: 32px 37px 32px 55px;
    border-radius: 20px 20px 0px 0px;
  }

  .au_signup_body {
    border-radius: 0px 0px 20px 20px;
  }

  .au_google_btn img,
  .au_fb_btn img {
    width: 20px !important;
  }

  .au_hidden_pass {
    width: 15px;
  }

  .au_signup_form .form-group {
    margin-bottom: 10px;
  }

  .custom-control {
    margin-top: 15px;
  }

  .au_signup_form_main {
    margin: 30px 0px;
  }
}

/* wide screen end */
/* desktop screen */
@media (min-width:768px) and (max-width:991px) {
  .au_modal .au_signup_form {
    padding: 37px 90px !important;
  }

  .au_form_mx_width {
    max-width: 540px;
  }

  .au_custom_caret:after {
    bottom: 10px;
  }

  .au_select .dropdown-heading-value,
  .dropdown-heading {
    font-size: 9px;
    line-height: 18px !important;
    height: 20px !important;
  }

  .au_select .dropdown-heading {
    line-height: 10px !important;
  }

  .au_custom_select,
  .au_select .item-renderer input+span {
    font-size: 9px !important;
  }

  .au_select .item-renderer input+span:before {
    top: 0px !important;
    height: 16px;
    width: 16px;
  }

  .au_select .item-renderer input:checked+span:after {
    left: 6px;
    top: 4px;
    width: 4px;
    height: 7px;
    border-width: 0 2px 2px 0;
  }

  .au_form_upload_message p {
    height: 50px !important;
    font-size: 9px !important;
    height: 24px !important;
  }

  .au_paste_btn .input-group-text {
    height: 42px !important;
    width: 42px !important;
    padding: 4px !important;
  }

  .au_theme_btn {
    margin: 30px 0 0px;
  }

  .au_signup_header h4 {
    font-size: 14px;
  }

  img[alt="Logo"] {
    width: 60px !important;
  }

  .au_signup_form label,
  .au_signup_form label,
  .au_signup_form label u {
    font-size: 9px;
  }

  .au_signup_form input {
    font-size: 9px;
    padding: 2px 0px 5px;
    height: 15px;
  }

  .au_signup_form .remember {
    font-size: 12px;
  }

  .au_google_btn,
  .au_fb_btn {
    font-size: 14px;
    padding: 6px 17px;
  }

  .au_theme_btn {
    width: 70px;
    height: 24px;
    font-size: 11px;
  }

  .al_paste_btn .input-group-text {
    height: 60px !important;
    width: 60px !important;
  }

  .al_signup_header {
    padding: 32px 37px 32px 55px;
    border-radius: 20px 20px 0px 0px;
  }

  .au_signup_header {
    padding: 32px 37px 32px 55px;
    border-radius: 20px 20px 0px 0px;
  }

  .au_signup_body {
    border-radius: 0px 0px 20px 20px;
  }

  .au_google_btn img,
  .au_fb_btn img {
    width: 18px !important;
  }

  .au_hidden_pass {
    width: 15px;
  }

  .au_signup_form .form-group {
    margin-bottom: 10px;
  }

  .custom-control {
    margin-top: 15px;
  }

  .au_signup_form_main {
    margin: 30px 0px;
  }
}

/* desktop screen end */
/* tab screen */
@media (min-width:567px) and (max-width:768px) {

  .au_form_mx_width {
    max-width: 540px;
  }

  .au_modal .au_signup_form {
    padding: 37px 90px !important;
  }

  .au_custom_caret:after {
    bottom: 10px;
  }

  .au_select .dropdown-heading-value,
  .dropdown-heading {
    font-size: 9px;
    line-height: 18px !important;
    height: 20px !important;
  }

  .au_select .dropdown-heading {
    line-height: 10px !important;
  }

  .au_custom_select,
  .au_select .item-renderer input+span {
    font-size: 9px !important;
  }

  .au_select .item-renderer input+span:before {
    top: 0px !important;
    height: 16px;
    width: 16px;
  }

  .au_select .item-renderer input:checked+span:after {
    left: 6px;
    top: 4px;
    width: 4px;
    height: 7px;
    border-width: 0 2px 2px 0;
  }

  .au_form_upload_message p {
    height: 50px !important;
    font-size: 9px !important;
    height: 24px !important;
  }

  .au_paste_btn .input-group-text {
    height: 42px !important;
    width: 42px !important;
    padding: 4px !important;
  }

  .au_theme_btn {
    margin: 30px 0 0px;
  }

  .au_signup_header h4 {
    font-size: 14px;
  }

  img[alt="Logo"] {
    width: 60px !important;
  }

  .au_signup_form label,
  .au_signup_form label,
  .au_signup_form label u {
    font-size: 9px;
  }

  .au_signup_form input {
    font-size: 9px;
    padding: 2px 0px 5px;
    height: 15px;
  }

  .au_signup_form .remember {
    font-size: 12px;
  }

  .au_google_btn,
  .au_fb_btn {
    font-size: 14px;
    padding: 6px 17px;
  }

  .au_theme_btn {
    width: 70px;
    height: 24px;
    font-size: 11px;
  }

  .al_paste_btn .input-group-text {
    height: 60px !important;
    width: 60px !important;
  }

  .al_signup_header {
    padding: 32px 37px 32px 55px;
    border-radius: 20px 20px 0px 0px;
  }

  .au_signup_header {
    padding: 32px 37px 32px 55px;
    border-radius: 20px 20px 0px 0px;
  }

  .au_signup_body {
    border-radius: 0px 0px 20px 20px;
  }

  .au_google_btn img,
  .au_fb_btn img {
    width: 18px !important;
  }

  .au_hidden_pass {
    width: 15px;
  }

  .au_signup_form .form-group {
    margin-bottom: 10px;
  }

  .custom-control {
    margin-top: 15px;
  }
}

/* tab screen end */
/* mobile screen */
@media (min-width:0px) and (max-width:567px) {


  .au_form_mx_width {
    max-width: 540px;
  }

  .au_modal .au_signup_form {
    padding: 37px 50px !important;
  }

  .au_custom_caret:after {
    bottom: 10px;
  }

  .au_select .dropdown-heading-value,
  .dropdown-heading {
    font-size: 9px;
    line-height: 18px !important;
    height: 20px !important;
  }

  .au_select .dropdown-heading {
    line-height: 10px !important;
  }

  .au_custom_select,
  .au_select .item-renderer input+span {
    font-size: 9px !important;
  }

  .au_select .item-renderer input+span:before {
    top: 0px !important;
    height: 16px;
    width: 16px;
  }

  .au_select .item-renderer input:checked+span:after {
    left: 6px;
    top: 4px;
    width: 4px;
    height: 7px;
    border-width: 0 2px 2px 0;
  }

  .au_form_upload_message p {
    height: 50px !important;
    font-size: 9px !important;
    height: 24px !important;
  }

  .au_paste_btn .input-group-text {
    height: 42px !important;
    width: 42px !important;
    padding: 4px !important;
  }

  .au_theme_btn {
    margin: 30px 0 0px;
  }

  .au_signup_header h4 {
    font-size: 14px;
  }

  img[alt="Logo"] {
    width: 60px !important;
  }

  .au_signup_form label,
  .au_signup_form label,
  .au_signup_form label u {
    font-size: 9px;
  }

  .au_signup_form input {
    font-size: 9px;
    padding: 2px 0px 5px;
    height: 15px;
  }

  .au_signup_form .remember {
    font-size: 12px;
  }

  .au_google_btn,
  .au_fb_btn {
    font-size: 14px;
    padding: 6px 17px;
  }

  .au_theme_btn {
    width: 70px;
    height: 24px;
    font-size: 11px;
  }

  .al_paste_btn .input-group-text {
    height: 60px !important;
    width: 60px !important;
  }

  .al_signup_header {
    padding: 32px 37px 32px 55px;
    border-radius: 20px 20px 0px 0px;
  }

  .au_signup_header {
    padding: 32px 37px 32px 55px;
    border-radius: 20px 20px 0px 0px;
  }

  .au_signup_body {
    border-radius: 0px 0px 20px 20px;
  }

  .au_google_btn img,
  .au_fb_btn img {
    width: 18px !important;
  }

  .au_hidden_pass {
    width: 15px;
  }

  .au_signup_form .form-group {
    margin-bottom: 10px;
  }

  .custom-control {
    margin-top: 15px;
  }

  .au_order-xs-2 {
    order: 2;
  }

  .au_order-xs-2 h4 {
    text-align: center;
  }

  .au_order-xs-1 {
    order: 1;
    text-align: center;
  }

  .au_order-xs-1 img {
    float: initial !important;
    margin-bottom: 10px;
  }

  .au_signup_header {
    padding: 32px 37px 32px 37px;
  }

}

@media (min-width:0px) and (max-width:400px) {
  .au_order-xs-2 {
    order: 2;
  }

  .au_order-xs-2 h4 {
    text-align: center;
  }

  .au_order-xs-1 {
    order: 1;
    text-align: center;
  }

  .au_order-xs-1 img {
    float: initial !important;
    margin-bottom: 10px;
  }

  .au_signup_header {
    padding: 32px 37px 32px 37px;
  }

  .au_signup_body {
    padding: 0px !important;
  }

  .au_modal .au_signup_form {
    padding: 37px 30px !important;
  }
}

/* mobile screen end */